import { AuthenticationService } from './../../core/authenticationn.service';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'src/app/core/models';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  email_id: string;
  isValid: string;
  lnsURL=environment.lnsURL

  today: number = Date.now();
  activeTab :any;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  
  constructor(private authenticationService: AuthenticationService) {
    
      this.currentUser = JSON.parse(localStorage.getItem('boilerUser'));    
        // // console.log(this.currentUser);       
  }

   

  ngOnInit(): void {
    this.isValid = localStorage.getItem("backToken");
  }
  
  logout() {
    if(this.isValid){
      // // console.log("isBackToken",this.isValid);
      localStorage.removeItem('backToken');
      localStorage.removeItem("boilerUser")
      window.location.href=environment.lnsURL;
    }
    else{
    this.authenticationService.logout();
  }
  }
}
