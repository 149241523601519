import { APiService } from 'src/app/core/balco.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PlotChartsService } from 'src/app/core/charts/plot-charts.service';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

@Component({
  selector: 'app-theme2',
  templateUrl: './theme2.component.html',
  styleUrls: ['./theme2.component.scss'],
})
export class Theme2Component implements OnInit {
  @Input() graph4: any[];
  @Input() graph5: any[];
  [x: string]: any;
  isLoading: boolean;

  Realtimedata: any = [];
  Forecastdata: any = [];
  selectedDate:any;
  selectedDatenext:any;
  selectedForecastDatenext:any;
  selectedDateprevious:any;
  AFRdata1: any = [];
  Coaldata: any = [];
  PCCoaldata:any;
  AFRCoaldata:any;


  constructor(
    private toastr: ToastrService,
    private ApicallService: APiService,
    private object: FormBuilder,
    public dialog: MatDialog,
    private plotChartsService: PlotChartsService,
  ) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.selectedDatenext='1680373800';
    this.selectedForecastDatenext='1660847700';
    this.getTable();
    this.getforecastTable();
    this. getCOALTable();
   this. getAFRTable1();
   console.log(this.graph4,this.graph5)
  //  this.getPCcoal();
  //  this.getAFRcoal();
  
 }
 ngOnChanges() {
  
  this.drawPcChart();
  this.drawAFRChart();
 }

  getTable() {
    this.ApicallService.getTableValues(this.selectedDatenext).subscribe(
      (data: any) => {
        this.Realtimedata = data;
        // // console.log(this.Realtimedata, "data")
      },
      (err) => {
        // (err);

      }
    )
  }
  getforecastTable() {
    this.ApicallService.getForecastTableValues(this.selectedForecastDatenext).subscribe(
      (data: any) => {
        this.Forecastdata = data;
        // // console.log(this.Forecastdata, "data")
      },
      (err) => {
        // (err);

      }
    )
  }

  getPCcoal(){
    this.ApicallService.getpocoalTableValues(this.selectedDatenext).subscribe(
      (data: any) => {
        this.PCCoaldata = data;
        this.drawPcChart();
        console.log(this.PCCoaldata, "data")
      },
      (err) => {
        // (err);

      }
    )
  }
  getAFRcoal(){
    this.ApicallService.getafrcoalTableValues(this.selectedDatenext).subscribe(
      (data: any) => {
        this.AFRCoaldata = data;
        this.drawAFRChart();
        console.log(this.AFRCoaldata, "data")
      },
      (err) => {
        // (err);

      }
    )
  }
  // changeDropdown(types:any) {  
  //   this.selectedDatenext=types.target.value
  //   console.log(this.selectedDatenext)
  //   this.ApicallService.getTableValues(types.target.value).subscribe(
  //     (data: any) => {
  //       this.Realtimedata = data;
  //       // // console.log(this.Realtimedata, "data")
  //     },
  //     (err) => {
  //       // (err);
  //     }
  //   )
  //   this.selectedForecastDatenext = parseInt(types.target.value) +300;
  //   this.ApicallService.getForecastTableValues(this.selectedForecastDatenext).subscribe(
  //     (data: any) => {
  //       this.Forecastdata = data;
  //       // // console.log(this.Forecastdata, "data")
  //     },
  //     (err) => {
  //       // (err);

  //     }
  //   )
  //   this.ApicallService.getAFRTableValues1(this.selectedDatenext).subscribe(
  //     (data: any) => {
  //       this.AFRdata1 = data;
  //       // // console.log(this.AFRdata, "data")
  //     },
  //     (err) => {
  //       // (err);

  //     }
  //   )

  //   this.ApicallService.getCoalTableValues(this.selectedDatenext).subscribe(
  //     (data: any) => {
  //       this.Coaldata = data;
  //       // // console.log(this.AFRdata, "data")
  //     },
  //     (err) => {
  //       // (err);

  //     }
  //   )
  //   this.getPCcoal();
  //   this.getAFRcoal();
  // }
  getAFRTable1() {
    this.ApicallService.getAFRTableValues1(this.selectedDatenext).subscribe(
      (data: any) => {
        this.AFRdata1 = data;
        // // console.log(this.AFRdata, "data")
      },
      (err) => {
        // (err);

      }
    )

  }
  getCOALTable() {
    this.ApicallService.getCoalTableValues(this.selectedDatenext).subscribe(
      (data: any) => {
        this.Coaldata = data;
        // // console.log(this.AFRdata, "data")
      },
      (err) => {
        // (err);

      }
    )

  }
  onNextclick() {
    // console.log(this.startRealTime)
    this.selectedDatenext = parseInt(this.selectedDatenext) + 300;
    console.log(this.selectedDatenext)
    this.ApicallService.getTableValues(this.selectedDatenext).subscribe(
      (data: any) => {
        this.Realtimedata = data;
      },
      (err) => {
        // (err);
      }
    )
    // this.onclicknext = parseInt(this.onclicknext) + 300;
    this.selectedForecastDatenext = parseInt(this.selectedForecastDatenext) + 300;
    this.ApicallService.getForecastTableValues(this.selectedForecastDatenext).subscribe(
      (data: any) => {
        this.Forecastdata = data;
      },
      (err) => {
        // (err);
      }
    )
  }
  onPreviousclick() {
    this.selectedDatenext = parseInt(this.selectedDatenext) - 300;
    console.log(this.selectedDatenext)
    this.ApicallService.getTableValues(this.selectedDatenext).subscribe(
      (data: any) => {
        this.Realtimedata = data;
      },
      (err) => {
        // (err);
      }
    )
    this.selectedForecastDatenext = parseInt(this.selectedForecastDatenext) - 300;
    console.log(this.selectedForecastDatenext)
    this.ApicallService.getForecastTableValues(this.selectedForecastDatenext).subscribe(
      
      (data: any) => {
        this.Forecastdata = data;
      },
      (err) => {
        // (err);
      }
    )
}

drawPcChart() {

  setTimeout(() => {
    let ref = this;
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    let chart = am4core.create("ppediv1", am4charts.XYChart);
    chart.logo.disabled = true;
    chart.paddingRight = 25;
    chart.background.fill = am4core.color('#1e1e1e');

    // Add data
    chart.data = ref.graph4;
    chart.cursor = new am4charts.XYCursor();

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "obs_time";
    categoryAxis.title.text = "Time";
    categoryAxis.title.fill = am4core.color("#FFF");
    categoryAxis.title.fontSize = 12;
    categoryAxis.renderer.labels.template.fill = am4core.color('#fff');
    categoryAxis.start = 0.9;
    categoryAxis.end = 1;
    // categoryAxis.keepSelection = true;
    // categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 50;
    categoryAxis.renderer.labels.template.fontSize = 12;
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = categoryAxis;
    chart.zoomOutButton.disabled = true;
    chart.numberFormatter.numberFormat = "#.00";
    // chart.responsive.enabled = true;
    // categoryAxis.renderer.cellStartLocation = 0.1;
    // categoryAxis.renderer.cellEndLocation = 0.9;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis.min = 870;
    valueAxis.title.text = " PC Coal (TPH)";
    valueAxis.title.fill = am4core.color("#FFF");
    valueAxis.title.fontWeight = 'bold';
    // valueAxis.title.fontWeight = 'bold';
    valueAxis.title.fontSize = 12;
    valueAxis.renderer.labels.template.fontSize = 12;
    valueAxis.renderer.labels.template.fill = am4core.color('#fff');

    // let valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
    // // valueAxis.min = 870;
    // valueAxis1.title.text = " Temperature";
    // valueAxis1.title.fill = am4core.color("#FFF");
    // valueAxis1.title.fontWeight = 'bold';
    // // valueAxis.title.fontWeight = 'bold';
    // valueAxis1.title.fontSize = 12;
    // valueAxis1.renderer.labels.template.fontSize = 12;
    // valueAxis1.renderer.labels.template.fill = am4core.color('#fff');

    // let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis2.min = 0;
    // valueAxis2.maxPrecision = 0;
    // valueAxis2.title.text = "Feed (TPH)";
    // valueAxis.title.fill = am4core.color("#FFF");
    // valueAxis2.title.fill = am4core.color("#FFF");
    // valueAxis2.cursorTooltipEnabled = false;
    // valueAxis2.renderer.opposite = true;
    // valueAxis2.title.fontSize = 12;
    // valueAxis2.title.fontWeight = 'bold';
    // valueAxis2.renderer.labels.template.fontSize = 12;
    // valueAxis2.renderer.labels.template.fill = am4core.color('#fff');
    // // valueAxis2.min = 0;
    // valueAxis2.syncWithAxis = valueAxis;

    // valueAxis1.syncWithAxis = valueAxis;


    // Create series

    let series = chart.series.push(new am4charts.ColumnSeries());
    series.columns.template.width = am4core.percent(30);
    // series.clustered = false;
    // series.columns.template.dx = -10;
    series.dataFields.valueY = "main_coal_tph";
    series.dataFields.categoryX = "obs_time";
    // series.strokeWidth = 5;
    series.stroke = am4core.color("#94b6e6");
    series.fill = am4core.color("#94b6e6");
    series.legendSettings.valueText = "main_coal_tph [bold][/]";
    series.tooltipText = "Actual" + `:{${"main_coal_tph"}}`;
    // series.label.text ="{valueY}";

    let series1 = chart.series.push(new am4charts.LineSeries());
    // series1.columns.template.width = am4core.percent(30);
    // series1.clustered = false;  
    // series1.columns.template.dx = -20;
    series1.dataFields.valueY = "recom_coal";
    series1.dataFields.categoryX = "obs_time";
    series1.strokeWidth = 2;
    series1.stroke = am4core.color("#b3ff66");
    series1.fill = am4core.color("#b3ff66");
    series1.tooltipText =
      "Recommended" + `:{${"recom_coal"}}`;
    series1.legendSettings.valueText = "recom_coal [bold][/]";
    // series1.connect = false;

    let bullet2 = series1.bullets.push(new am4charts.Bullet());
    bullet2.fill = am4core.color("#4464a8");
    let circleBullet = series1.bullets.push(new am4charts.CircleBullet());
    circleBullet.circle.radius = 3;

    // kiln_feed

    //  let series2 = chart.series.push(new am4charts.LineSeries());
    // // series1.columns.template.width = am4core.percent(30);
    // // series1.clustered = false;  
    // // series1.columns.template.dx = -20;
    // series2.dataFields.valueY = "kiln_feed";
    // series2.dataFields.categoryX = "date_time";
    // series2.strokeWidth = 2;
    // series2.stroke = am4core.color("#ff9225");
    // series2.fill = am4core.color("#ff9225");
    // series2.tooltipText =
    //   "Kiln Feed" + `:{${"kiln_feed"}}`;
    // series2.legendSettings.valueText = "kiln_feed [bold][/]";
    // // series2.connect = false;
    // series2.yAxis = valueAxis2;

    // let bullet1 = series1.bullets.push(new am4charts.Bullet());
    // bullet1.fill = am4core.color("#ff9225");
    // let circleBullet1 = series2.bullets.push(new am4charts.CircleBullet());
    // circleBullet1.circle.radius = 3;



    // Add legend
    // chart.legend = new am4charts.Legend();
    // chart.legend.position = "top";
    // chart.legend.labels.template.fill = am4core.color('#fff');
    // chart.legend.labels.template.fontWeight = 'bold';
    // chart.exporting.menu = new am4core.ExportMenu();
    // chart.exporting.formatOptions.getKey("xlsx").disabled = true;
    // chart.exporting.formatOptions.getKey("json").disabled = true;
    // chart.exporting.formatOptions.getKey("html").disabled = true;
    // chart.exporting.formatOptions.getKey("jpg").disabled = true;
    // chart.exporting.formatOptions.getKey("png").disabled = true;
    // chart.exporting.formatOptions.getKey("svg").disabled = true;
    // chart.exporting.formatOptions.getKey("pdf").disabled = true;


    // chart.exporting.filePrefix = "Cyc_6_Avg_Temp";
    //Add scrollbar
    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX.parent = chart.topAxesContainer
    chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
    chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
    chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
    chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
    chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
    chart.scrollbarX.minHeight = 7;
    // Applied on hover
    chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
    chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
    chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
    // Applied on mouse down
    chart.scrollbarX.startGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
    chart.scrollbarX.endGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
    chart.scrollbarX.thumb.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");


    chart.tooltip.label.maxWidth = 100;
    chart.tooltip.label.wrap = true;


    // ref.loaded = true;
    // ref.isLoading=false;

  }, 100);



}
drawAFRChart() {
  setTimeout(() => {
    let ref = this;
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    let chart = am4core.create("fc2div1", am4charts.XYChart);
    chart.logo.disabled = true;
    chart.paddingRight = 25;
    chart.background.fill = am4core.color('#1e1e1e');
    chart.zoomOutButton.disabled = true;
    chart.numberFormatter.numberFormat = "#.00";
    // chart.tooltip.label.fill = am4core.color("#fff");

    // Add data
    chart.data = ref.graph5;



    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "obs_time";
    categoryAxis.title.text = "Time";
    categoryAxis.title.fill = am4core.color("#FFF");
    categoryAxis.title.fontSize = 12;
    // categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.labels.template.fill = am4core.color('#fff');
    categoryAxis.renderer.minGridDistance = 50;
    categoryAxis.renderer.labels.template.fontSize = 12;
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = categoryAxis;
    categoryAxis.start = 0.9;
    categoryAxis.end = 1;
    

    // categoryAxis.tooltipDateFormat = 'd MMM, hh-mm a';
    // categoryAxis.renderer.grid.template.stroke = "#fff";
    // categoryAxis.renderer.cellStartLocation = 0.1;
    // categoryAxis.renderer.cellEndLocation = 0.9;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis.min = 0;
    // valueAxis.max = 600;
    valueAxis.title.text = "AFR (M3/H)";
    valueAxis.title.fill = am4core.color("#FFF");
    valueAxis.title.fontWeight = 'bold';
    valueAxis.title.fontSize = 12;
    valueAxis.renderer.labels.template.fontSize = 12;
    valueAxis.renderer.labels.template.fill = am4core.color('#fff');

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.columns.template.width = am4core.percent(50);
    // series.clustered = false;
    // series.columns.template.dx = -10;
    series.dataFields.valueY = "liquid_afr_flow_rate";
    series.dataFields.categoryX = "obs_time";
    series.tooltipText = "Actual" + `:{${"liquid_afr_flow_rate"}}`;
    // series.strokeWidth = 5;
    series.stroke = am4core.color("#f8cecc");
    series.fill = am4core.color("#f8cecc");
    // series.tooltip.label.propertyFields.fill = "#fff";
    series.legendSettings.valueText = "liquid_afr_flow_rate [bold][/]";
    // series.label.text ="{valueY}";

    let series1 = chart.series.push(new am4charts.LineSeries());
    // series1.columns.template.width = am4core.percent(50);
    // series1.clustered = false;  
    // series1.columns.template.dx = -20;
    series1.dataFields.valueY = "recom_afr";
    series1.dataFields.categoryX = "obs_time";
    series1.tooltipText = "Recommended" + `:{${"recom_afr"}}`;
    series1.strokeWidth = 2;
    series1.stroke = am4core.color("#b3ff66");
    series1.fill = am4core.color("#b3ff66");
    series1.legendSettings.labelText = "recom_afr [bold][/]";
    // series1.connect = false;

    let bullet2 = series1.bullets.push(new am4charts.Bullet());
    bullet2.fill = am4core.color("#4464a8");
    let circleBullet = series1.bullets.push(new am4charts.CircleBullet());
    circleBullet.circle.radius = 3;


    // Add legend
    // chart.legend = new am4charts.Legend();
    // chart.legend.position = "top";
    // chart.exporting.menu = new am4core.ExportMenu();
    // chart.exporting.formatOptions.getKey("xlsx").disabled = true;
    // chart.exporting.formatOptions.getKey("json").disabled = true;
    // chart.exporting.formatOptions.getKey("html").disabled = true;
    // chart.exporting.formatOptions.getKey("jpg").disabled = true;
    // chart.exporting.formatOptions.getKey("png").disabled = true;
    // chart.exporting.formatOptions.getKey("svg").disabled = true;
    // chart.exporting.formatOptions.getKey("pdf").disabled = true;
  
    //Add scrollbar
    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX.parent = chart.topAxesContainer
    chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
    chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
    chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
    chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
    chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
    chart.scrollbarX.minHeight = 7;
    // Applied on hover
    chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
    chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
    chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
    // Applied on mouse down
    chart.scrollbarX.startGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
    chart.scrollbarX.endGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
    chart.scrollbarX.thumb.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");


    chart.tooltip.label.maxWidth = 150;
    chart.tooltip.label.wrap = true;


    // ref.loaded = true;
    // ref.isLoading=false;

  }, 100);
}
}
