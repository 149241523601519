<section>
  <div>
    <div class="mainGridRgtTitl"
      style="display:flex;flex-direction:row;justify-content:space-between;padding-bottom: 1%;margin-bottom: 1%;">AFR
      Recommendation
     
      <!-- <select class="global_dropdown" style=" max-width: max-content;
      float: right;
      padding: 10px;  
      font-size: 13px;
      padding-top: 8px;
      padding-bottom: 8px;
      /* margin-bottom: 1%;margin-top: 1%; */
      " (change)="changeDropdown($event)">
        <option value="1680373800">
          Apr 2nd 2023
        </option>
        <option value="1680460200">
          Apr 3rd 2023
        </option>
        <option value="1680546600">
          Apr 4th 2023
        </option>
        <option value="1680633000">
          Apr 5th 2023
        </option>
      </select> -->
    </div>
    <!-- <div class="row" style="padding-left: 1%;
      padding-right: 1%;">
      <div class="col-md-12">
        <select class="global_dropdown" style=" max-width: max-content;
              float: right;
              padding: 10px;  
              font-size: 13px;
              padding-top: 8px;
              padding-bottom: 8px;
              margin-bottom: 1%;margin-top: 1%;" (change)="changeDropdown($event)">
          <option value="1660847400">
            Aug 19th 2022
          </option>
          <option value="1660933800">
            Aug 20th 2022
          </option>
          <option value="1661020200">
            Aug 21th 2022
          </option>
          <option value="1661106600">
            Aug 22th 2022
          </option>
        </select>
      </div>
    </div> -->
    <div class="col-md-12">
      <span style=" color: #fff; float: right;">
        <tr *ngFor="let vval of tableVal  ">
          <td>Time -&nbsp;{{vval.obs_time | date:'HH:mm'}}
          </td>
        </tr>
      </span>
    </div>
    <!-- <div class="row" style="padding: 1%;">
      <div class="col-md-6">
        <table class="tableStyle">
          <tr>
            <td style="font-size: 15px;color: yellow; ">Daily Average Coal Reduced (%) </td>
          </tr>
          <tr *ngFor="let vval of Coaldata  ">
           
            <td>{{vval.daily_coal_per}} &nbsp;&nbsp;&nbsp;
              <img src="../../../../assets/images/Redarrow.gif" style="width: 30px;" />
            </td>
          </tr>
        </table>
      </div>
      <div class="col-md-6">
        <table class="tableStyle">
          <tr>
            <td style="font-size: 15px;color: yellow; "> Daily Average AFR Increased (%) </td>
          </tr>
          <tr *ngFor="let vval of  AFRdata1 ">
      
            <td>{{vval.daily_afr_per}}&nbsp;&nbsp;&nbsp;
              <img src="../../../../assets/images/GreenArrow.gif" style="width: 30px;" />
            </td>

          </tr>
        </table>
      </div>
    
    </div> -->
    <div class="row" style="padding: 1%;">
      <div class="col-md-6">
        <div class="red" *ngFor="let vval of CoalDe  ">
          <label for="temp">Daily Average Coal Reduced (%)</label>
          <div *ngIf="vval.daily_coal_per">
            <label class="per">{{vval.daily_coal_per}}</label>&nbsp;&nbsp;&nbsp;
            <img src="../../../../assets/images/Redarrow.gif" style="width: 30px;" />
          </div>
          <div *ngIf="!vval.daily_coal_per"><label class="per">No data</label></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="red" *ngFor="let vval of AfrInc  ">
          <label for="temp">Daily Average AFR Increased (%)</label>
          <div *ngIf="vval.daily_afr_per">
            <label class="per">{{vval.daily_afr_per}}</label>&nbsp;&nbsp;&nbsp;
            <img src="../../../../assets/images/GreenArrow.gif" style="width: 30px;" />
          </div>
          <div *ngIf="!vval.daily_afr_per"><label class="per">No data</label></div>
        </div>
      </div>
    </div>
    <div class="theme1PlotBox">
      <div class="row">
        <div class="col-md-6 " style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 17px;">
          Liquid AFR vs Main Coal
        </div>
        <div class="col-md-5">
          <!-- <select class="global_dropdown" style=" max-width: max-content;
          float: right;
          padding: 10px;  
          font-size: 13px;
          padding-top: 8px;
          padding-bottom: 8px;
          margin-bottom: 1%;margin-top: 1%;" (change)="changeDropdown($event)">
      <option value="1660847400">
        Aug 19th 2022
      </option>
      <option value="1660933800">
        Aug 20th 2022
      </option>
      <option value="1661020200">
        Aug 21th 2022
      </option>
      <option value="1661106600">
        Aug 22th 2022
      </option>
    </select> -->
        </div>
      </div>
      <div class="theme1PlotBoxGraph">
        <div id="ppediv">

        </div>
      </div>
    </div>


    <div class="row" style="padding: 1%;">
      <div class="col-md-12">
      
        <table class="tableStyle1">
          <tr>
            <th colspan="2" style="font-size: 15px;color: yellow;text-align: center;border-radius: 10px;">Constrained</th>
            <th colspan="2" style="font-size: 15px;color: yellow;text-align: center;border-radius: 10px;">Recommended</th>
          </tr>
          <tr>
            <td style="font-size: 15px;color: yellow;">Temperature (°C)</td>
            <td style="font-size: 15px;color: yellow;">CO (PPM)</td>
            <td style="font-size: 15px;color: yellow;">Coal (TPH)</td>
            <td style="font-size: 15px;color: yellow;">AFR (M3/Hr)</td>
          </tr>
          <tr  *ngFor="let vval of tableVal  ">
             <td>{{vval.temperature}}</td>
             <td>{{vval.daily_co}}</td>
             <td>{{vval.daily_recommended_coal}}</td>
             <td>{{vval.daily_recommended_afr}}</td>
          </tr>
        </table>
      </div>
    </div>
    <div style="display: flex;flex-direction:row;justify-content: space-between;padding: 1%;
    margin-top: 1%;
">
<div>
  <span *ngIf="this.selectedDatenext>'1680373800'"><button mat-raised-button color="primary"
      (click)="onPreviousclick()"> Previous</button></span>
  <span *ngIf="this.selectedDatenext=='1680373800'"><button mat-raised-button  disabled style="border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;">
      Previous</button></span>
</div>
<button mat-raised-button color="primary" (click)="onNextclick()"> Next</button>
</div>


  </div>
</section>
