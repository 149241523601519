import { APiService } from 'src/app/core/balco.service';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// import { ExcelsheetComponent } from 'src/app/components/excelsheet/excelsheet.component';
import { environment } from 'src/environments/environment';
import { PlotChartsService } from 'src/app/core/charts/plot-charts.service';
// import * as pdfMake from 'pdfmake/build/pdfmake';
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
// import * as html2pdf from 'html2pdf.js';
// pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})

export class DashboardComponent implements OnInit {


  lnsURL = environment.lnsURL;
  public isValid: string;

  imageToggle = 1;
  imageFix = '100%';
  startRealTime = '1680373800';
  selectedDatenext: any;
  // pdfMake.vfs = pdfFonts.pdfMake.vfs;




  SelectTheme = [
    // 'Data Sources & CPPS',
    // 'Water Utilization',
    // 'Non- CHG Emissions',
    // 'Wastage Accunting',
    // 'Reports Extraction'
    // 'KPI Validation',
    'GreenOps - Data Config.',
    'Emissions Accounting',
    'Emissions Reduction',
    'Energy, Water & Wastage',
    'Validation - Workflow',
    'Reports Extraction',
    // 'Non CHG Emissions',
    // 'Wastage Accounting',
    // 'AFR Recommendation',
    // 'AFR Vs Coal Trends',
    // 'BS & SR',

  ];

  // SelectedTheme = 'AFR Recommendation';Energy Accounting
  SelectedTheme = 'GreenOps - Data Config.';
  Valuesdaywise: any;
  appliedDateFilters: any = {};
  maxDate: Date = new Date(Date.now() - 86400000);
  minDate: Date = new Date('2022');
  startdatef: any;
  graphValueafr: any = [];
  graphValueafr1: any = [];
  graphValueafr2: any = [];
  PCCoaldata: any = [];
  AFRCoaldata: any = [];
  AFRdata: any = [];
  Realtimedata: any = [];
  Forecastdata: any = [];
  selectedForecastDatenext: any;
  selectedDateprevious: any;
  AFRdata1: any = [];
  Coaldata: any = [];
  ghgs1: any;
  ghgs2: any;
  colorMix1: any = [];
  htmlContent: any;
  s1Inten: any;
  s2Inten: any;
  http: any;

  constructor(private plotChartsService: PlotChartsService, private ApicallService: APiService, public dialog: MatDialog,) {
  }

  ngOnInit(): void {
    this.colorMix1 = 'G'
    this.getGHG1();
    this.getGHG2();
    this.getpdfdata();
    this.getScope1();
    this.getScope2();
    setInterval(() => {
      this.getScope1();
      this.getScope2();
      this.getGHG1();
      this.getGHG2();
    }, 5000);
    this.selectedDatenext = '1680373800'
    let dte = new Date(Date.now() - 86400000);
    this.appliedDateFilters['start_date'] = dte

    // console.log(this.appliedDateFilters['start_date'])
    this.isValid = localStorage.getItem("backToken");

    this.imageToggle = 2
    setTimeout(() => {
      this.imageToggle = 1;
    }, 5000);

    this.getDaywisedata();
    this.getCoGraph();
    this.getCoGraph1();
    this.getAFrGraph();
    this.getAFRcoal();
    this.getPCcoal();
    this.getAFRTable();
    this.getAFRTable1();
    this.getCOALTable();
    // this.getGHG1();
    // this.getGHG2();

  }
  downloadExcel() {
    this.ApicallService.downloadExcelFile();
  }
  // downloadPdf() {
  //   const filePath = 'assets/esg.html'; // Replace with the actual file path in the assets folder

  //   this.http.get(filePath, { responseType: 'text' }).subscribe((htmlContent) => {
  //     const fileName = 'esg.pdf'; // Replace with the desired file name

  //     this.ApicallService.downloadHtmlAsPdf(htmlContent, fileName);
  //   });
  // }

  // downloadPdf() {
  //   const filePath = 'assets/esg.html'; // Replace with the actual file path in the assets folder

  //   this.http.get(filePath, { responseType: 'text' }).subscribe((htmlContent) => {
  //     const element = document.createElement('div');
  //     element.innerHTML = htmlContent;

  //     html2pdf()
  //       .from(element)
  //       .save('download.pdf');
  //   });
  // }

  
  downloadExcelFile() {
    const fileName = 'ESG_REPORT.xlsx'; // Replace with your Excel file name

    this.http.get(`assets/documentation/${fileName}`, { responseType: 'blob' })
      .subscribe((blob: Blob) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        console.log(fileName)
        link.click();
      });
  }
  getScope1() {
    this.ApicallService.getIntensityS1().subscribe(
      (response: any) => {
        this.s1Inten = response;
        // console.log(this.htmlContent, "data")
      },
      (err) => {
        // (err);

      }
    )
  }
  getScope2() {
    this.ApicallService.getIntensityS2().subscribe(
      (response: any) => {
        this.s2Inten = response;
        // console.log(this.htmlContent, "data")
      },
      (err) => {
        // (err);

      }
    )
  }

  Validationcheck(value: any) {

    if (value == 'R') {
      this.colorMix1 = 'R'
      // console.log('Grenn', this.colorMix1)
    } else {
      this.colorMix1 = 'G'
      // console.log('G', this.colorMix1)
    }

  }

  getpdfdata() {
    this.ApicallService.getHtmltodownload().subscribe(
      (response: any) => {
        this.htmlContent = response.html;
        console.log(this.htmlContent, "data")
      },
      (err) => {
        // (err);

      }
    )
  }
  generatePdf() {
    const documentDefinition = {
      content: [
        {
          text: 'BS & SR',
          style: 'header'
        },
        {
          text: 'SECTION - A: GENERAL DISCLOSURES',
          style: 'subheader'
        },
        {
          style: 'tableExample',
          table: {
            body: [
              ['Column 1', 'Column 2', 'Column 3'],
              ['Column 1', 'Column 2', 'Column 3'], ['Column 1', 'Column 2', 'Column 3'], ['Column 1', 'Column 2', 'Column 3'], ['Column 1', 'Column 2', 'Column 3'],
              ['Column 1', 'Column 2', 'Column 3'], ['Column 1', 'Column 2', 'Column 3'], ['Column 1', 'Column 2', 'Column 3'], ['Column 1', 'Column 2', 'Column 3'],
              ['Column 1', 'Column 2', 'Column 3'], ['Column 1', 'Column 2', 'Column 3'], ['Column 1', 'Column 2', 'Column 3'], ['Column 1', 'Column 2', 'Column 3'],
              ['Column 1', 'Column 2', 'Column 3'], ['Column 1', 'Column 2', 'Column 3'],
              ['One value goes here', 'Another one here', 'OK?']
            ]
          }
        },
        {
          text: 'SECTION - B: MANAGEMENT AND PROCESS DISCLOSURES',
          style: 'subheader'
        },
        {
          style: 'tableExample',
          table: {
            body: [
              ['Column 1', 'Column 2', 'Column 3', 'Column 1', 'Column 2', 'Column 3', 'Column 1'],
              ['Column 1', 'Column 2', 'Column 3', 'Column 1', 'Column 2', 'Column 3', 'Column 1'],
              ['Column 1', 'Column 2', 'Column 3', 'Column 1', 'Column 2', 'Column 3', 'Column 1'],
              ['Column 1', 'Column 2', 'Column 3', 'Column 1', 'Column 2', 'Column 3', 'Column 1'],
              ['Column 1', 'Column 2', 'Column 3', 'Column 1', 'Column 2', 'Column 3', 'Column 1'],
              ['Column 1', 'Column 2', 'Column 3', 'Column 1', 'Column 2', 'Column 3', 'Column 1'],
              ['Column 1', 'Column 2', 'Column 3', 'Column 1', 'Column 2', 'Column 3', 'Column 1'],
            ]
          }
        },
        {
          text: 'SECTION - C: PRINCIPLE WISE PERFORMANCE DISCLOSURE',
          style: 'subheader'
        },
        {
          text: 'PRINCIPLE 1 - GOVERNANCE',
          style: 'subheader'
        },
        {
          style: 'tableExample',
          table: {
            body: [
              ['Column 1', 'Column 2', 'Column 3'],
              ['Column 1', 'Column 2', 'Column 3'],
            ]
          }
        },
        {
          style: 'tableExample',
          table: {
            body: [
              ['Column 1', 'Column 2', 'Column 3'],
              ['Column 1', 'Column 2', 'Column 3'],
            ]
          }
        },
        {
          style: 'tableExample',
          table: {
            body: [
              ['Column 1', 'Column 2', 'Column 3'],
              ['Column 1', 'Column 2', 'Column 3'],
            ]
          }
        },
        {
          style: 'tableExample',
          table: {
            body: [
              ['Column 1', 'Column 2'],
              ['Column 1', 'Column 2'],
            ]
          }
        },
        {
          text: 'PRINCIPLE 2 - PRODUCT SUSTABILITY',
          style: 'subheader'
        },
        {
          style: 'tableExample',
          table: {
            body: [
              ['Column 1', 'Column 2'],
              ['Column 1', 'Column 2'],

            ]
          }
        },
        {
          text: 'PRINCIPLE 3 - WORKFORCE',
          style: 'subheader'
        },
        {
          text: 'PRINCIPLE 4 - STAKEHOLDERS',
          style: 'subheader'
        },
        {
          text: 'PRINCIPLE 5 - HUMAN RIGHTS',
          style: 'subheader'
        },
        {
          text: 'PRINCIPLE 6 - ENVIRONMENT',
          style: 'subheader'
        },
        {
          text: 'PRINCIPLE 7 - COMPLAINCE',

          style: 'subheader'
        },
        {
          text: 'PRINCIPLE 8 - SOCIAL INCLUSIVE',
          style: 'subheader'
        },
        {
          text: 'PRINCIPLE 9 - CONSUMER COMPLAINT',
          style: 'subheader'
        },

        {
          text: 'It is possible to apply multiple styles, by passing an array. This paragraph uses two styles: quote and small. When multiple styles are provided, they are evaluated in the specified order which is important in case they define the same properties',
          style: ['quote', 'small']
        }
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true
        },
        subheader: {
          fontSize: 15,
          bold: true
        },
        quote: {
          italics: true
        },
        small: {
          fontSize: 8
        }
      }

    };

    // pdfMake.createPdf(documentDefinition).open();
  }




  // radio buttom theme event
  onItemChange(event) {
    // this.SelectedTheme = event.SelectTheme.value;
    this.imageToggle = 2;

    setTimeout(() => {
      this.imageToggle = 1;
    }, 5000);

  }
  toggleImage(value: any) {
    if (value == 1) {
      this.imageToggle = 1;
    }
    else {
      this.imageToggle = 2;
    }
  }
  // changeDropdown(types: any) {
  //   this.selectedDatenext = types.target.value
  //   // console.log(this.selectedDatenext, "jhv")
  //   this.getDaywisedata();

  // }

  changeDate() {
    this.getDaywisedata();
    this.getCoGraph();
    this.getCoGraph1();
    this.getAFrGraph();
    this.getAFRcoal();
    this.getPCcoal();
    this.getAFRTable();
    this.getAFRTable1();
    this.getCOALTable();
  }


  // theme 3 api calls
  getGHG1() {
    this.ApicallService.getGHGs1().subscribe(
      (data: any) => {
        this.ghgs1 = data;
        // console.log(this.ghgs1, "data")
      },
      (err) => {
        // (err);

      }
    )
  }

  getGHG2() {
    this.ApicallService.getGHGs2().subscribe(
      (data: any) => {
        this.ghgs2 = data;
        console.log(this.ghgs2, "data")
      },
      (err) => {
        // (err);

      }
    )
  }


  getDaywisedata() {
    this.startdatef = new Date(new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)).getTime() / 1000;
    this.ApicallService.getDayWisedata(this.startdatef).subscribe(
      (data: any) => {
        this.Valuesdaywise = data;
        console.log(this.Valuesdaywise, "data")
      },
      (err) => {
        // (err);

      }
    )

  }
  getCoGraph() {

    let ref = this;
    this.startdatef = new Date(new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)).getTime() / 1000;
    console.log(this.startdatef)
    ref.plotChartsService.getCOdata1(this.startdatef).subscribe((res) => {
      ref.graphValueafr = res;
      ref.graphValueafr.map((cvalue: any) => {
        let dt = cvalue.obs_time;
        let forcast = cvalue.coal_before;
        let temp = cvalue.coal_after;
      })
      // console.log("jhbh", ref.graphValueafr)
      // ref.drawFC2Chart();

    });
  }
  getCoGraph1() {
    let ref = this;
    this.startdatef = new Date(new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)).getTime() / 1000;
    ref.plotChartsService.getCOdata2(this.startdatef).subscribe((res) => {
      ref.graphValueafr1 = res;
      ref.graphValueafr1.map((cvalue: any) => {
        let dt = cvalue.obs_time;
        let forcast = cvalue.co_before;
        let temp = cvalue.co_after;
      })
      // console.log("jhbh", ref.graphValueafr)
      // ref.drawFC2Chart();

    });
  }



  // theme 4 api calls

  getAFrGraph() {
    let ref = this;
    this.startdatef = new Date(new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)).getTime() / 1000;
    ref.plotChartsService.getAfrGraphData(this.startdatef).subscribe((res) => {
      ref.graphValueafr2 = res;
      ref.graphValueafr2.map((cvalue: any) => {
        let dt = cvalue.obs_time;
        let forcast = cvalue.forecast_cyc_6_avg_temp;
        let temp = cvalue.cyc_6_avg_temp;
      })
      // console.log("jhbh", ref.graphValueafr)
      // ref.drawFC2Chart();

    });
  }
  getAFRTable() {
    this.startdatef = new Date(new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)).getTime() / 1000;
    this.ApicallService.getAFRTableValues(this.startdatef).subscribe(
      (data: any) => {
        this.AFRdata = data;
        // // console.log(this.AFRdata, "data")
      },
      (err) => {
        // (err);

      }
    )

  }
  getAFRTable1() {
    this.startdatef = new Date(new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)).getTime() / 1000;
    this.ApicallService.getAFRTableValues1(this.startdatef).subscribe(
      (data: any) => {
        this.AFRdata1 = data;
        // // console.log(this.AFRdata, "data")
      },
      (err) => {
        // (err);

      }
    )

  }
  getCOALTable() {
    this.startdatef = new Date(new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)).getTime() / 1000;
    this.ApicallService.getCoalTableValues(this.startdatef).subscribe(
      (data: any) => {
        this.Coaldata = data;
        // // console.log(this.AFRdata, "data")
      },
      (err) => {
        // (err);

      }
    )

  }



  // theme 2 api calls
  getPCcoal() {
    this.startdatef = new Date(new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)).getTime() / 1000;
    this.ApicallService.getpocoalTableValues(this.startdatef).subscribe(
      (data: any) => {
        this.PCCoaldata = data;
        console.log(this.PCCoaldata, "data")
      },
      (err) => {
        // (err);

      }
    )
  }
  getAFRcoal() {
    this.startdatef = new Date(new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)).getTime() / 1000;
    this.ApicallService.getafrcoalTableValues(this.startdatef).subscribe(
      (data: any) => {
        this.AFRCoaldata = data;
        console.log(this.AFRCoaldata, "data")
      },
      (err) => {
        // (err);

      }
    )
  }




  cleanUp() {
    localStorage.removeItem('boilerUser');
    localStorage.removeItem('backToken');
  }





}
