import { PlotChartsService } from './../../../core/charts/plot-charts.service';
// import { BalcoService } from './../../../core/balco.service';
import { APiService } from 'src/app/core/balco.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { HttpClient } from '@angular/common/http';



am4core.useTheme(am4themes_animated);
const baseUrl2 = 'https://api.dev.cement4.0.livnsense.com/';
@Component({
  selector: 'app-theme7',
  templateUrl: './theme7.component.html',
  styleUrls: ['./theme7.component.scss']
})


export class Theme7Component implements OnInit {

  today: number = Date.now();
  kips: any;
  name: string = '';
  file: any;
  selectedFile = null;
  selectedFileType = 'kiln_quality';
  selectedDate1Next: any;
  selectProductType = [];
  selectedValue;
  fileName = '';
  response: string;
  apiresp: any;
  // fileType:any;

  // simpleForm: FormGroup;
  submitted = true;
  alert: boolean
  files: any;
  error: String
  data: String
  res: string;
  nghg: any;
  s3Inten: any;
  constructor(private ApicallService: APiService,
    private plotChartsService: PlotChartsService,
    public dialog: MatDialog,
    private http: HttpClient) {
  }

  ngOnInit() {
    this.getNonG();
    this.getScope3();
    // this.selectedFile = 'kiln_quality';

  }
  changeDropdown(types: any) {
    this.selectedFileType = types.target.value
    // console.log(this.selectedFileType, "jhv")

  }
  getFile(event: any) {
    this.selectedFile = event.target.files[0];
    // console.log("file", this.selectedFile)
  }
  getScope3() {
    this.ApicallService.getIntensityS3().subscribe(
      (response: any) => {
        this.s3Inten = response;
        // console.log(this.htmlContent, "data")
      },
      (err) => {
        // (err);

      }
    )
  }

  getNonG() {
    this.ApicallService.getNonGHG().subscribe((response) => {
      this.nghg = response;
      console.log("values", this.nghg)
    },
      (err) => {
        // (err);

      }
    )
  }

  submitData() {
    let type = this.selectedFileType;
    let fd = new FormData();
    // console.log(fd);
    fd.append('files', this.selectedFile, this.selectedFile.name);
    // console.log( fd.append('files' , this.selectedFile, this.selectedFile.name));
    this.ApicallService.uploadFiles(type, fd).subscribe(
      (response) => {
        this.apiresp = response.body;
        setTimeout(() => {
          this.apiresp = '';
        }, 2000);

        console.log(this.apiresp);
        if (response.status == 200) {
          this.response = "Uploaded successfully."
          setTimeout(() => {
            this.res = '';
          }, 2000);

        }
        if (response.status == 204) {
          this.res = "file is empty please input the data."
          setTimeout(() => {
            this.res = '';
          }, 2000);
        }
        if (response.status == 409) {
          this.apiresp = response;
          console.log(response);
          setTimeout(() => {
            this.apiresp = '';
          }, 2000);
        }
        if (response.status == 206) {
          this.res = "Type is Missing."
          setTimeout(() => {
            this.res = '';
          }, 2000);
        }
      }, (error) => {
        console.log(error);

        this.error = 'Uploaded File not matched with type'
        setTimeout(() => {
          this.error = '';
        }, 2000);

      }
    )
  }











}







