<section>
    <div>
      <div class="mainGridRgtTitl"
        style="display:flex;flex-direction:row;justify-content:space-between;padding-bottom: 1%;">
        Carbon Savings
        <!-- <select class="global_dropdown" style=" max-width: max-content;
        float: right;
        padding: 10px;  
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 8px;
       
        " (change)="changeDropdown($event)">
          <option value="1680373800">
            Apr 2nd 2023
          </option>
          <option value="1680460200">
            Apr 3rd 2023
          </option>
          <option value="1680546600">
            Apr 4th 2023
          </option>
          <option value="1680633000">
            Apr 5th 2023
          </option>
        </select> -->
      </div>
   <!-- <div class="col-md-12">
        <span style=" color: #fff; float: right;">
          <tr *ngFor="let vval of AFRdata  ">
            <td>Time -&nbsp;{{vval.obs_time | date:'HH:mm'}}
            </td>
          </tr>
        </span>
      </div> -->
      
      <!-- <div style="overflow-y: scroll;    max-height: 399px;
      overflow-x: hidden;
      padding: 10px;"> -->
      <div class="theme1PlotBox">
        <div class="row">
          <div class="col-md-6 " style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 17px;">
           CO<sub>2</sub> Savings
          </div>
          <div *ngFor="let sval of savings  "  class="col-md-5"  style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 17px;float: right;">
            <span style="font-weight: bold; margin-left: 2px; color:yellowgreen; font-size: 17px;">{{sval.co2_saving_per_ton}}</span> kg/Ton of kiln feed
           </div>
        </div>
        <div class="theme1PlotBoxGraph">
          <div id="ppediv">
  
          </div>
        </div>
        
      </div>
      <div class="theme1PlotBox">
        <div class="row">
          <div class="col-md-6 " style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 17px;">
            CO Savings
          </div>
          <div *ngFor="let sval of savings  "  class="col-md-5"  style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 17px;float: right;">
           <span style="font-weight: bold; margin-left: 2px; color:yellowgreen; font-size: 17px;">{{sval.co_saving_per_ton}}</span> kg/Ton of kiln feed
          </div>
        </div>
        <div class="theme1PlotBoxGraph">
          <div id="ppediv1">
  
          </div>
        </div>
        
      </div>
    <!-- </div> -->
  
  
     
      <!-- <div style="display: flex;flex-direction:row;justify-content: space-between;padding: 1%;
      margin-top: 1%;
  ">
  <div>
    <span *ngIf="this.selectedDatenext>'1660847400'"><button mat-raised-button color="primary"
        (click)="onPreviousclick()"> Previous</button></span>
    <span *ngIf="this.selectedDatenext=='1660847400'"><button mat-raised-button  disabled style="border: 1px solid #999999;
      background-color: #cccccc;
      color: #666666;">
        Previous</button></span>
  </div>
  <button mat-raised-button color="primary" (click)="onNextclick()"> Next</button>
  </div> -->
  
  
    </div>
  </section>
 