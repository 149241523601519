import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const baseUrl = environment['baseUrl'];
const baseUrl2 = environment['baseUrl2'];

@Injectable({
  providedIn: 'root',
})
export class PlotChartsService {
  constructor(private http: HttpClient) { }



  // 
  getFC1GraphData(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl2}actual_forecast_graph_01?start_time=${starttime}`).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          // console.log(error);
        }
      )
    );
  }

  getFC2GraphData(starttime: any): Observable<any> {
    // real_time_tracking_graph?start_time=
    // actual_forecast_graph_02?start_time=
    return this.http.get(`${baseUrl2}actual_forecast_graph_02?start_time=${starttime}`).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          // console.log(error);
        }
      )
    );
  }

  getAfrGraphData(starttime: any): Observable<any> {
    // afr_recommendation_graph?start_time=1661040000
    // realtime_opt_graph?start_time=
    return this.http.get(`${baseUrl2}afr_recommendation_graph?start_time=${starttime}`).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          // console.log(error);
        }
      )
    );
  }

getCOdata1(starttime: any): Observable<any> {
  // afr_recommendation_graph?start_time=1661040000
  // realtime_opt_graph?start_time=
  return this.http.get(`${baseUrl2}co2_graph_sql?start_time=${starttime}`).pipe(
    map(
      (response: any) => {
        return response;
      },
      (error) => {
        // console.log(error);
      }
    )
  );
}
getCOdata2(starttime: any): Observable<any> {
  // afr_recommendation_graph?start_time=1661040000
  // realtime_opt_graph?start_time=
  return this.http.get(`${baseUrl2}co_graph_sql?start_time=${starttime}`).pipe(
    map(
      (response: any) => {
        return response;
      },
      (error) => {
        // console.log(error);
      }
    )
  );
}

}
