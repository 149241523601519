
import { PlotChartsService } from './../../../core/charts/plot-charts.service';
// import { BalcoService } from './../../../core/balco.service';
import { APiService } from 'src/app/core/balco.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';



am4core.useTheme(am4themes_animated);
@Component({
  selector: 'app-theme1',
  templateUrl: './theme1.component.html',
  styleUrls: ['./theme1.component.scss']
})
export class Theme1Component implements OnInit {

  today: number = Date.now();
  kips: any;
  graphValue: any = [];
  graphValuefc1: any = [];
  fcgraph1: any;
  graphValuefc2: any = [];
  fcgraph2: any;
  selectedDatenext: any;
  selectedDate1Next: any;
  selectProductType = [];
  selectedValue;

  constructor(private ApicallService: APiService,
    private plotChartsService: PlotChartsService,
    public dialog: MatDialog) {
  }

  ngOnInit() {
    this.selectedDatenext = '1680373800';
    // this.selectedDate1Next='1660933799';
    this.getKpiValues();
    this.getKpi2Values();
    // this.drawDummyChart();
    this.drawFC1Chart();
    this.drawFC2Chart();
    // this.testChart();
  }
  changeDropdown(types: any) {
    this.selectedDatenext = types.target.value
    // console.log(this.selectedDatenext, "jhv")
    let ref = this;
    ref.plotChartsService.getFC1GraphData(this.selectedDatenext).subscribe((res) => {
      ref.graphValuefc1 = res;
      console.log(this.graphValuefc1[0].forecast_cyc_6_avg_temp)
      ref.graphValuefc1.map((cvalue: any) => {
        let dt = cvalue.obs_time;
        let forcast = cvalue.forecast_cyc_6_avg_temp;
        let temp = cvalue.cyc_6_avg_temp;
      })
      // console.log("jhbh", ref.graphValuefc1)
      ref.drawFC1Chart();
      // ref.testChart();
      // ref.drawFC2Chart();

    });

    ref.plotChartsService.getFC2GraphData(this.selectedDatenext).subscribe((res) => {
      ref.graphValuefc2 = res;
      ref.graphValuefc2.map((cvalue: any) => {
        let dt2 = cvalue.obs_time;
        let forcast2 = cvalue.forecast_cyc_6_outlet_co;
        let temp2 = cvalue.cyc_6_outlet_co;
      })
      // console.log("jhbh", ref.graphValuefc2)
      ref.drawFC2Chart();
      // ref.drawFC2Chart();

    });

  }

  getKpiValues() {
    let ref = this;
    ref.plotChartsService.getFC1GraphData(this.selectedDatenext).subscribe((res) => {
      ref.graphValuefc1 = res;
      
      ref.graphValuefc1.map((cvalue: any) => {
        let dt = cvalue.obs_time;
        let forcast = cvalue.forecast_cyc_6_avg_temp;
        let temp = cvalue.cyc_6_avg_temp;
      })
      // console.log("jhbh", ref.graphValuefc1)
      ref.drawFC1Chart();
      // ref.testChart();
      ref.drawFC2Chart();

    });

  }
  getKpi2Values() {
    let ref = this;
    ref.plotChartsService.getFC2GraphData(this.selectedDatenext).subscribe((res) => {
      ref.graphValuefc2 = res;
      ref.graphValuefc2.map((cvalue: any) => {
        let dt2 = cvalue.obs_time;
        let forcast2 = cvalue.forecast_cyc_6_outlet_co;
        let temp2 = cvalue.cyc_6_outlet_co;
      })
      // console.log("jhbh", ref.graphValuefc2)
      ref.drawFC2Chart();
      // ref.drawFC2Chart();

    });
  }


  drawFC1Chart() {

    setTimeout(() => {
      let ref = this;
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      let chart = am4core.create("ppediv", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 25;
      chart.background.fill = am4core.color('#1e1e1e');

      // Add data
      chart.data = ref.graphValuefc1;
      chart.cursor = new am4charts.XYCursor();

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time";
      categoryAxis.title.text = "Timestamp";
      categoryAxis.title.fill = am4core.color("#FFF");
      categoryAxis.title.fontSize = 20;
      categoryAxis.renderer.labels.template.fill = am4core.color('#fff');
      // categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      // categoryAxis.renderer.cellStartLocation = 0.1;
      // categoryAxis.renderer.cellEndLocation = 0.9;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 915;
      valueAxis.title.text = " Temperature";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.title.fontWeight = 'bold';
      // valueAxis.title.fontWeight = 'bold';
      valueAxis.title.fontSize = 12;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color('#fff');





      // Create series

      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = "cyc_6_avg_temp";
      series.dataFields.categoryX = "obs_time";
      series.strokeWidth = 5;
      series.stroke = am4core.color("#F05264");
      series.fill = am4core.color("#F05264");
      series.legendSettings.valueText = "cyc_6_avg_temp [bold][/]";
      series.tooltipText = "CYC-6 AVERAGE TEMPERATURE" + `:{${"cyc_6_avg_temp"}}`;
      // series.label.text ="{valueY}";

      let series1 = chart.series.push(new am4charts.LineSeries());
      series1.dataFields.valueY = "forecast_cyc_6_avg_temp";
      series1.dataFields.categoryX = "obs_time";
      series1.strokeWidth = 5;
      series1.stroke = am4core.color("#FEB157");
      series1.fill = am4core.color("#FEB157");
      series1.tooltipText =
        "Forecast CYC-6 Average Temperarture" + `:{${"forecast_cyc_6_avg_temp"}}`;
      series1.legendSettings.valueText = "forecast_cyc_6_avg_temp [bold][/]";



      // Add legend
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "top";
      // chart.legend.labels.template.fill = am4core.color('#fff');
      // chart.legend.labels.template.fontWeight = 'bold';

      //Add scrollbar
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.topAxesContainer
      chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.minHeight = 7;
      // Applied on hover
      chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      // Applied on mouse down
      chart.scrollbarX.startGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");


      chart.tooltip.label.maxWidth = 100;
      chart.tooltip.label.wrap = true;


      // ref.loaded = true;
      // ref.isLoading=false;

    }, 100);



  }
  drawFC2Chart() {
    setTimeout(() => {
      let ref = this;
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      let chart = am4core.create("fc2div", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 25;
      chart.background.fill = am4core.color('#1e1e1e');
      // chart.tooltip.label.fill = am4core.color("#fff");

      // Add data
      chart.data = ref.graphValuefc2;



      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time";
      categoryAxis.title.text = "Timestamp";
      categoryAxis.title.fill = am4core.color("#FFF");
      categoryAxis.title.fontSize = 20;
      // categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.labels.template.fill = am4core.color('#fff');
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;

      // categoryAxis.tooltipDateFormat = 'd MMM, hh-mm a';
      // categoryAxis.renderer.grid.template.stroke = "#fff";
      // categoryAxis.renderer.cellStartLocation = 0.1;
      // categoryAxis.renderer.cellEndLocation = 0.9;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.title.text = "CO Level";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.title.fontWeight = 'bold';
      valueAxis.title.fontSize = 12;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color('#fff');

      // Create series
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = "cyc_6_outlet_co";
      series.dataFields.categoryX = "obs_time";
      series.tooltipText = "CYC-6 Outlet CO" + `:{${"cyc_6_outlet_co"}}`;
      series.strokeWidth = 5;
      series.stroke = am4core.color("#00cccc");
      series.fill = am4core.color("#00cccc");
      // series.tooltip.label.propertyFields.fill = "#fff";
      series.legendSettings.valueText = "cyc_6_outlet_co [bold][/]";
      // series.label.text ="{valueY}";

      let series1 = chart.series.push(new am4charts.LineSeries());
      series1.dataFields.valueY = "forecast_cyc_6_outlet_co";
      series1.dataFields.categoryX = "obs_time";
      series1.tooltipText = "Forecast CYC-6 Outlet CO" + `:{${"forecast_cyc_6_outlet_co"}}`;
      series1.strokeWidth = 5;
      series1.stroke = am4core.color("#b3ff66");
      series1.fill = am4core.color("#b3ff66");
      series1.legendSettings.labelText = "forecast_cyc_6_outlet_co [bold][/]";

      // Add legend
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "top";

      //Add scrollbar
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.topAxesContainer
      chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.minHeight = 7;
      // Applied on hover
      chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      // Applied on mouse down
      chart.scrollbarX.startGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");


      chart.tooltip.label.maxWidth = 150;
      chart.tooltip.label.wrap = true;


      // ref.loaded = true;
      // ref.isLoading=false;

    }, 100);
  }




}






