import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../core/authenticationn.service';
import { NotificationService } from '../../core/notification.service';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  decodeToken: any;
  hide = true;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private notifyService: NotificationService
  ) {
    var checkStorage = localStorage.getItem('boilerUser');
    if (checkStorage !== '') {
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({   
      email_id: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  // for accessing to form fields
  get fval() {
    // // console.log();
    return this.loginForm.controls;
  }

  onFormSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService
      .login(this.fval.email_id.value, this.fval.password.value)
      .subscribe(
        (data) => {
          const token = data.token.substr(2, data.token.length - 3);
          this.decodeToken = jwt_decode(token);
          this.router.navigate(['dashboard']);
        
        },
        (error) => {
          this.notifyService.showError('Incorrect Credentials', '', {
            positionClass: 'toast-top-right',
          });
          this.loading = false;
        }
      );
  }
}
