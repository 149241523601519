<!-- <p>theme7 works!</p> -->
<section>
    <div >
        <div class="mainGridRgtTitl"
           >
            <div class="row">
                <div class="col-md-6 " style="margin-left: 2px; color:#ffffff; font-size: 17px;width: unset;">
                    Non - GHG
                   </div>
                   <!-- <div  class="col-md-5"  style=" margin-left: 2px; color:#ffffff; font-size: 17px;float: right;">
                    Intensity
                     <span *ngFor="let items3 of s3Inten" style="font-weight: bold; margin-left: 2px; color:yellowgreen; font-size: 17px;">{{items3.energy_intensity}}</span>
                    </div> -->
            </div>
        </div>
    </div>


    <div class="row" style="padding: 1%;">
        <div class="col-md-12">
            <table class="tableStyle1">
                <tr>
                    <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                        Parameter</th>
                    <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">NOx
                    </th>
                    <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                        SOx</th>
                    <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                        PM</th>
                    <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                        POP</th>
                    <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                        VOC</th>
                    <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                        HAP</th>
                    <!-- <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">Savings (%)</th> -->
                </tr>
                <tr *ngFor="let item of nghg">
                    <!-- <td>{{ item.id }}</td> -->
                    <!-- <td>{{ item.obs_time }}</td> -->
                    <th style="font-size: 16px;color: yellow;">{{ item.steps }}</th>
                    <td>{{ item.nox }}</td>
                    <td>{{ item.sox }}</td>
                    <td>{{ item.pm }}</td>
                    <td>{{ item.voc }}</td>
                    <td>{{ item.poc }}</td>
                    <td>{{ item.hap }}</td>
                  </tr>
            </table>
        </div>
    </div>

    <div >
        <div class="mainGridRgtTitl"
           >
            <div class="row">
                <div class="col-md-6 " style="margin-left: 2px; color:#ffffff; font-size: 17px;width: unset;">
                    Compliance
                   </div>
                   <!-- <div  class="col-md-5"  style=" margin-left: 2px; color:#ffffff; font-size: 17px;float: right;">
                    Intensity
                     <span style="font-weight: bold; margin-left: 2px; color:yellowgreen; font-size: 17px;">--</span>
                    </div> -->
            </div>
        </div>
    </div>
    <div class="row" style="padding: 1%;">
        <div class="col-md-12">

            <table class="tableStyle1">
               
                <tr>
                    <th style="font-size: 16px;color: yellow;">Prevention and Control of Pollution</th>
                    <td style="font-size: 16px;">✓</td>
                    
                </tr>
                <tr>
                    <th style="font-size: 16px;color: yellow;">Environment protection act and rules</th>
                    <td style="font-size: 16px;">✓</td>
                    
                </tr>
                
               
                
                

            </table>
        </div>
    </div>



</section>