<section>
  <div>
    <div class="mainGridRgtTitl"
      style="display:flex;flex-direction:row;justify-content:space-between;padding-bottom: 1%;">AFR Vs Coal Trends
      <!-- <select class="global_dropdown" style=" max-width: max-content;
            float: right;
            padding: 10px;  
            font-size: 13px;
            padding-top: 8px;
            padding-bottom: 8px;
            /* margin-bottom: 1%;margin-top: 1%; */
            " (change)="changeDropdown($event)">
        <option value="1680373800">
          Apr 2nd 2023
        </option>
        <option value="1680460200">
          Apr 3rd 2023
        </option>
        <option value="1680546600">
          Apr 4th 2023
        </option>
        <option value="1680633000">
          Apr 5th 2023
        </option>
      </select> -->
    </div>
    
    <div class="theme1PlotBox">
      <div class="row">
        <div class="col-md-12 " style="font-weight: bold; margin-left: 2px; color:white; font-size: 17px;">
          PC coal - Actual Vs Recommended
        </div>
      </div>
      <div class="theme1PlotBoxGraph">
  
        <div id="ppediv1"></div>
      </div>
    </div>

    <div class="theme1PlotBox1">
      <div class="row">
        <div class="col-md-12 " style="font-weight: bold; margin-left: 2px; color:white; font-size: 17px;">
          AFR - Actual Vs Recommended
          
        </div>
      </div>
      <div class="theme1PlotBoxGraph">
  
        <div id="fc2div1"></div>
      </div>
    </div>
  </div>
</section>