import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { saveAs } from 'file-saver';
// import * as pdfMake from 'pdfmake/build/pdfmake';
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
// pdfMake.vfs = pdfFonts.pdfMake.vfs;


// Configure pdfmake with fonts



const baseUrl = environment['baseUrl'];
const baseUrl2 = environment['baseUrl2'];

@Injectable({
  providedIn: 'root',
})
export class APiService {
  [x: string]: any;

  public potNo = new BehaviorSubject(1303);
  public boilerNo = new BehaviorSubject('Z701');
  getSelectedAlgoConfig: any;

  constructor(private http: HttpClient) { }

  // DASHBOARD PAGE -- REALTIME PARAMETERS TABLE API INTEGRATION
  getHtmltodownload(): Observable<any> {
    return this.http.get(`${baseUrl2}brsr/report`).pipe(
      map(
        (response: any) => {
          if (response.length == 0) {
            return [];
          } else { return response; }

        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        // // console.log("Error is handled",err);
        return value;
      })
    );
  }
  getIntensityS1(): Observable<any> {
    return this.http.get(`${baseUrl2}brsr/env_scope/1`).pipe(
      map(
        (response: any) => {
          if (response.length == 0) {
            return [];
          } else { return response; }

        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        // // console.log("Error is handled",err);
        return value;
      })
    );
  }
  getIntensityS2(): Observable<any> {
    return this.http.get(`${baseUrl2}brsr/env_scope/2`).pipe(
      map(
        (response: any) => {
          if (response.length == 0) {
            return [];
          } else { return response; }

        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        // // console.log("Error is handled",err);
        return value;
      })
    );
  }
  getIntensityS3(): Observable<any> {
    return this.http.get(`${baseUrl2}brsr/env_scope/3`).pipe(
      map(
        (response: any) => {
          if (response.length == 0) {
            return [];
          } else { return response; }

        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        // // console.log("Error is handled",err);
        return value;
      })
    );
  }
  getIntensityS4(): Observable<any> {
    return this.http.get(`${baseUrl2}brsr/env_scope/4`).pipe(
      map(
        (response: any) => {
          if (response.length == 0) {
            return [];
          } else { return response; }

        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        // // console.log("Error is handled",err);
        return value;
      })
    );
  }

  downloadExcelFile() {
    const filePath = 'assets/documentation/ESG_REPORT.xlsx'; // Replace with the actual file path in the assets folder

    this.http.get(filePath, { responseType: 'blob' }).subscribe((blob) => {
      const fileName = 'ESG_REPORT.xlsx'; // Replace with the desired file name

      saveAs(blob, fileName);
    });
  }
  getGHGs1(): Observable<any> {
    return this.http.get(`${baseUrl2}brsr/ghg/1`).pipe(
      map(
        (response: any) => {
          if (response.length == 0) {
            return [];
          } else { return response; }

        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        // // console.log("Error is handled",err);
        return value;
      })
    );
  }
  getGHGs2(): Observable<any> {
    return this.http.get(`${baseUrl2}brsr/ghg/2`).pipe(
      map(
        (response: any) => {
          if (response.length == 0) {
            return [];
          } else { return response; }

        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        // // console.log("Error is handled",err);
        return value;
      })
    );
  }
  getNonGHG(): Observable<any> {
    return this.http.get(`${baseUrl2}brsr/ghg/0`).pipe(
      map(
        (response: any) => {
          if (response.length == 0) {
            return [];
          } else { return response; }

        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        // // console.log("Error is handled",err);
        return value;
      })
    );
  }
  getEWW() {
    return this.http.get(`${baseUrl2}brsr/eww/1?report_year=2022`).pipe(
      map(
        (response: any) => {
          if (response.length == 0) {
            return [];
          } else { return response; }

        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        // // console.log("Error is handled",err);
        return value;
      })
    );

  }


  getTableValues(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl2}filter_optimization_data?epoch_time=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length == 0) {
            return [];
          } else { return response; }

        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        // // console.log("Error is handled",err);
        return value;
      })
    );
  }
  // DASHBOARD PAGE -- FORECAST TABLE API INTEGRATION
  getForecastTableValues(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl2}forecast_data?date_time=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length == 0) {
            return [];
          } else { return response; }

        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        // // console.log("Error is handled",err);
        return value;
      })
    );
  }

  getDayWisedata(starttime: any) {
    // emission_summary_data?start_time=1680460200
    return this.http.get(`${baseUrl2}emission_summary_data?start_time=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length == 0) {
            return [];
          } else { return response; }

        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        // // console.log("Error is handled",err);
        return value;
      })
    );
  }

  getAFRTableValues(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl2}afr_table_data?date_time=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length == 0) {
            return [];
          } else { return response; }

        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        // // console.log("Error is handled",err);
        return value;
      })
    );
  }
  // https://api.dev.cement4.0.livnsense.com/po_coal_graph?start_time=1680373800
  getpocoalTableValues(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl2}po_coal_graph?start_time=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length == 0) {
            return [];
          } else { return response; }

        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        // // console.log("Error is handled",err);
        return value;
      })
    );
  }
  getafrcoalTableValues(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl2}afr_coal_graph?start_time=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length == 0) {
            return [];
          } else { return response; }

        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        // // console.log("Error is handled",err);
        return value;
      })
    );
  }
  getAFRTableValues1(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl2}daily_average_afr_per?start_time=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length == 0) {
            return [];
          } else { return response; }

        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        // // console.log("Error is handled",err);
        return value;
      })
    );
  }
  getCoalTableValues(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl2}daily_average_coal_per?start_time=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length == 0) {
            return [];
          } else { return response; }

        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        // // console.log("Error is handled",err);
        return value;
      })
    );
  }


  // Theme 3 Api Calls
  getCOtableValues1(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl2}co_reduction_data?start_time=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length == 0) {
            return [];
          } else { return response; }

        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        // // console.log("Error is handled",err);
        return value;
      })
    );
  }

  // Theme5apicalls
  // uploadFilekpi(filetype:any): Observable<any>{
  // return this.http.post<any>(`${baseUrl2}kpi/file_upload?type=${filetype}`,{}).pipe(map((response: any) => {
  //   return response
  // }, (error: any) => {
  //    console.log(error);
  // }
  // ));
  // }

  uploadFiles(_type: any, fd: any): Observable<any> {

    return this.http.post<any>(`${baseUrl}kpi/file_upload?type=${_type}`, fd, { observe: "response" })
      .pipe(catchError(this.handleError))

  }


  // method to get report table and graph values

  // getTableGraphValues(): Observable<any> {
  //   // const sDate = new Date(filters['start_date']).toISOString();
  //   // const eDate = new Date(filters['end_date']).toISOString();
  //   return this.http.get(`${baseUrl2}realtime_opt_graph?start_time=1660847400&end_time=1660933500`).pipe(
  //     map(
  //       (response: any) => {
  //         if (response.length==0) {
  //          return [];
  //         }else{ return response;}

  //       },
  //       (error) => {
  //         // // console.log(error);
  //       }
  //     ),
  //     catchError(err => {
  //       let value = [];
  //       // // console.log("Error is handled",err);
  //       return value;
  //     })
  //   );
  // }


  // getfc2TableGraphValues(): Observable<any> {
  //   // const sDate = new Date(filters['start_date']).toISOString();
  //   // const eDate = new Date(filters['end_date']).toISOString();
  //   return this.http.get(`${baseUrl2}actual_forecast_graph_02?start_time=1660847400&end_time=1660933500`).pipe(
  //     map(
  //       (response: any) => {
  //         if (response.length==0) {
  //          return [];
  //         }else{ return response;}

  //       },
  //       (error) => {
  //         // // console.log(error);
  //       }
  //     ),
  //     catchError(err => {
  //       let value = [];
  //       // // console.log("Error is handled",err);
  //       return value;
  //     })
  //   );
  // }
  // getfc1TableGraphValues(): Observable<any> {
  //   // const sDate = new Date(filters['start_date']).toISOString();
  //   // const eDate = new Date(filters['end_date']).toISOString();
  //   return this.http.get(`${baseUrl2}actual_forecast_graph_01?start_time=1660847400&end_time=1660933500`).pipe(
  //     map(
  //       (response: any) => {
  //         if (response.length==0) {
  //          return [];
  //         }else{ return response;}

  //       },
  //       (error) => {
  //         // // console.log(error);
  //       }
  //     ),
  //     catchError(err => {
  //       let value = [];
  //       // // console.log("Error is handled",err);
  //       return value;
  //     })
  //   );
  // }

  // method to get report dosing table values 

  // getDosingTableData(filters:any): Observable<any> {
  //   const sDate = new Date(filters['start_date']).toISOString();
  //   const eDate = new Date(filters['end_date']).toISOString();
  //   return this.htttp.get(`${baseUrl2}dashboard/dosing_table?start_date=${sDate}&end_date=${eDate}`).pipe(
  //     map(
  //       (response: any) => {
  //         if (response.length==0) {
  //          return [];
  //         }else{ return response;}

  //       },
  //       (error) => {
  //         // // console.log(error);
  //       }
  //     ),
  //     catchError(err => {
  //       let value = [];
  //       // // console.log("Error is handled",err);
  //       return value;
  //     })
  //   );
  // }



  // getROITableValues(boilerNo): Observable<any> {
  //   return this.htttp.get(`${baseUrl2}dashboard/roi_data?boiler=${boilerNo}`).pipe(
  //     map(
  //       (response: any) => {
  //         if (response.length==0) {
  //          return [];
  //         }else{ return response;}

  //       },
  //       (error) => {
  //         // // console.log(error);
  //       }
  //     ),
  //     catchError(err => {
  //       let value = [];
  //       // // console.log("Error is handled",err);
  //       return value;
  //     })
  //   );
  // }

  // uploadDosing(body:any): Observable<any> {
  //   return this.htttp.post(`${baseUrl2}dashboard/dosing_data`,body).pipe(
  //     map(
  //       (response: any) => {
  //         if (response.length==0) {
  //          return [];
  //         }else{ return response;}

  //       },
  //       (error) => {
  //         // // console.log(error);
  //       }
  //     ),
  //     catchError(err => {
  //       let value = [];
  //       // // console.log("Error is handled",err);
  //       return value;
  //     })
  //   );
  // }

}


