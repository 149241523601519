<app-navbar></app-navbar>
<div class="row" style="padding-top:4%;padding-right: 1.2%;max-height:100px;">
    <div style="display: flex;flex-direction: row;justify-content: right;">
        
        <div *ngIf="SelectedTheme=='Emissions Accounting'|| SelectedTheme=='Reports Extraction' || SelectedTheme=='Reports Extraction'"   class="date-picker">
            <!-- <div  class="col-md-4" style="display:grid;">
                <span style="color: #fff;">2022-23</span>
            </div> -->
            
            <!-- <mat-form-field class="padding-test" appearance="outline">
                <mat-label style="color: white">Date</mat-label>
                <input style="color: white; font-size: 15px" matInput [min]="minDate" [max]="maxDate"
                    [matDatepicker]="startdate" placeholder="Choose Start Date"
                    [(ngModel)]="appliedDateFilters.start_date" [disabled]="false" />
                <mat-datepicker-toggle matSuffix [for]="startdate"></mat-datepicker-toggle>
                <mat-datepicker #startdate></mat-datepicker>
            </mat-form-field> -->
        </div>
        <button *ngIf="SelectedTheme=='Emissions Accounting'|| SelectedTheme=='Reports Extraction' || SelectedTheme=='Reports Extraction'" title="Select the date and click." style="
        font-size: 16px;
        width: 110px;
        cursor: pointer;
        background-color: #302c2c;
        outline: unset;
        margin-left: 4%;
        padding-top: 2px;
        border: 1px solid rgba(255, 255, 255, 0.38);
        height: 35px;
      " mat-raised-button color="primary" class="get-sm-btn" (click)="changeDate()">
            2022-23
        </button>


    </div>

</div>

<div class="row" style="padding-right: 1.2%;max-height:733px;overflow:auto;">
    <div class="col-md-2" style="
        background-image: linear-gradient(rgb(30, 30, 30), rgb(23, 23, 23));
        border: 1px solid #403c3c;
        min-height: 613px;
        max-height: max-content;
        box-shadow: 0 0 20px #000;
      ">
        <div *ngFor="let x of SelectTheme">
            <div class="radio" style="
            border: 1px solid rgba(255, 255, 255, 0.38);
            border-radius: 6px;
            box-shadow: 5px 5px 14px #3f3f3f, -5px -5px 14px black;
          " [ngClass]=" SelectedTheme==x ? 'btn-th-active' :  'btn-th' ">
                <label><input type="radio" class="radio" [(ngModel)]="SelectedTheme" value="{{x}}"
                        (change)="onItemChange($event)"><span>{{x}}</span></label>
            </div>
        </div>

        <!-- <div id="div_refresh" *ngIf="isValid">
                <a href={{lnsURL}}>
                    <span (click)="cleanUp()"><span style="color: white;">BackButton</span></span>
                </a>
            </div> -->
    </div>

    <div *ngIf="SelectedTheme == 'AFR Vs Coal Trends'|| SelectedTheme == 'AFR Recommendation' || SelectedTheme != 'Emissions Reduction' && SelectedTheme != 'Emissions Accounting' && SelectedTheme != 'Reports Extraction' && SelectedTheme != 'GreenOps - Data Config.'  && SelectedTheme!='Validation - Workflow'"
        class="col-md-5 h-100">
        <div style="   background-image: linear-gradient(rgb(30, 30, 30), rgb(23, 23, 23)); 
            border: 1px solid #403C3C; 
            min-height:548px;max-height: max-content;
                 box-shadow: 0 0 20px #000">
            <div class="asImgBoxTop pt-2">
                <div class="asImgBoxTopTxt">Pyro Process Twin</div>

            </div>
            <div class="row" style="margin:unset;padding:1%;">
                <div class="col-md-12" style="padding-left:unset;">
                    <button (click)="toggleImage(1)" class="image-toggle"><i class="bi bi-card-image"></i> Pyro
                        Process Flow</button>
                    <!-- <button (click)="toggleImage(2)" class="image-toggle"><i class="bi bi-card-image"></i>
                            Cyclones</button> -->

                </div>
            </div>
            <div class="row">
                <div class="col-md-12" style="display:grid;" *ngIf="imageToggle == 1">
                    <img class="img-fluid" src="assets\img\cement_nw.svg" alt="Pyro Process flow"
                        style="width:495px; margin:auto;">
                </div>
            </div>
            <div style="height: 512px;display:grid;" *ngIf="imageToggle == 2">
                <img class="img-fluid" src="assets\img\V2.png" style="
                    margin: auto;
                    height: 500px;
                    padding: 1%;" alt="Pyro Process">
            </div>
            <!-- <div style="width: 100%;padding-top: 3%;">
                    <img class="img-fluid" src="assets\img\cement_img.svg" alt="AluminumSmeltingGraphic"
                        style="width:100%; margin:auto; height: 542px;">
                </div> -->
        </div>
    </div>

    <div *ngIf="SelectedTheme=='Emissions Accounting'" class="col-md-5">
        <div style="   background-image: linear-gradient(rgb(30, 30, 30), rgb(23, 23, 23)); 
            border: 1px solid #403C3C; 
            min-height:613px;max-height: max-content;     
            box-shadow: 0 0 20px #000">
            <section>
                <div>
                    <div class="mainGridRgtTitl2">
                        <div class="row">
                            <div class="col-md-6 "
                                style="margin-left: 2px; color:#ffffff; font-size: 17px;width: unset;">
                                GHG - Scope1
                            </div>
                            <div class="col-md-5"
                                style=" margin-left: 2px; color:#ffffff; font-size: 17px;float: right;">
                                Intensity
                                <span *ngFor="let items2 of s2Inten"
                                    style="font-weight: bold; margin-left: 2px; color:yellowgreen; font-size: 17px;">{{items2.scope2_intensity}}</span>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row" style="padding: 1%;">
                    <div class="col-md-12">

                        <table class="tableStyle1">
                            <tr>
                                <th colspan="1"
                                    style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                    Parameter</th>
                                <th colspan="1"
                                    style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                    CO<sub>2</sub></th>
                                <th colspan="1"
                                    style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                    CH<sub>4</sub></th>
                                <th colspan="1"
                                    style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                    N<sub>2</sub>O</th>
                                <th colspan="1"
                                    style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                    HFC</th>
                                <th colspan="1"
                                    style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                    PFC</th>
                                <th colspan="1"
                                    style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                    SF<sub>6</sub></th>
                                <th colspan="1"
                                    style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                    NF<sub>3</sub></th>
                            <tr *ngFor="let item of ghgs1">
                                <!-- <td>{{ item.id }}</td> -->
                                <!-- <td>{{ item.obs_time }}</td> -->
                                <th style="font-size: 16px;color: yellow;">{{ item.steps }}</th>
                                <td>{{ item.co2 }}</td>
                                <td>{{ item.ch4 }}</td>
                                <td>{{ item.n2o }}</td>
                                <td>{{ item.hfc }}</td>
                                <td>{{ item.pfc }}</td>
                                <td>{{ item.sf6 }}</td>
                                <td>{{ item.nf3 }}</td>
                            </tr>
                            <!-- </tr>
                            <tr>
                                <th style="font-size: 16px;color: yellow;">Milling</th>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                            </tr>
                            <tr>
                                <th style="font-size: 16px;color: yellow;">Pre-Calciner</th>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                            </tr>
                            <tr>
                                <th style="font-size: 16px;color: yellow;">Kiln</th>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                            </tr>
                            <tr>
                                <th style="font-size: 16px;color: yellow;">Cooler</th>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                            </tr>
                            <tr>
                                <th style="font-size: 16px;color: yellow;">Boiler</th>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                            </tr>
                            <tr>
                                <th style="font-size: 16px;color: yellow;">Total</th>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                            </tr>
                            <tr>
                                <th style="font-size: 16px;color: yellow;">Total CO2 </th>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                            </tr> -->
                            <!-- <tr>
                                <th style="font-size: 16px;color: yellow;">Ware House</th>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                                <td style="font-size: 16px;"></td>
                            </tr> -->


                        </table>
                    </div>
                </div>

                <div>
                    <div class="mainGridRgtTitl2">
                        <div class="row">
                            <div class="col-md-6 "
                                style="margin-left: 2px; color:#ffffff; font-size: 17px;width: unset;">
                                GHG - Scope2
                            </div>
                            <div class="col-md-5"
                                style=" margin-left: 2px; color:#ffffff; font-size: 17px;float: right;">
                                Intensity
                                <span *ngFor="let items2 of s2Inten"
                                    style="font-weight: bold; margin-left: 2px; color:yellowgreen; font-size: 17px;">{{items2.scope2_intensity}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="padding: 1%;">
                        <div class="col-md-12">

                            <table class="tableStyle1">
                                <tr>
                                    <th colspan="1"
                                        style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                        Parameter</th>
                                    <th colspan="1"
                                        style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                        CO<sub>2</sub></th>
                                    <th colspan="1"
                                        style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                        CH<sub>4</sub></th>
                                    <th colspan="1"
                                        style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                        N<sub>2</sub>O</th>
                                    <th colspan="1"
                                        style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                        HFC</th>
                                    <th colspan="1"
                                        style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                        PFC</th>
                                    <th colspan="1"
                                        style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                        SF<sub>6</sub></th>
                                    <th colspan="1"
                                        style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">
                                        NF<sub>3</sub></th>
                                </tr>
                                <tr *ngFor="let item of ghgs2">
                                    <!-- <td>{{ item.id }}</td> -->
                                    <!-- <td>{{ item.obs_time }}</td> -->
                                    <th style="font-size: 16px;color: yellow;">{{ item.steps }}</th>
                                    <td>{{ item.co2 }}</td>
                                    <td>{{ item.ch4 }}</td>
                                    <td>{{ item.n2o }}</td>
                                    <td>{{ item.hfc }}</td>
                                    <td>{{ item.pfc }}</td>
                                    <td>{{ item.sf6 }}</td>
                                    <td>{{ item.nf3 }}</td>
                                </tr>
                                <!-- <tr>
                                    <th style="font-size: 16px;color: yellow;">Motors</th>
                                    <td style="font-size: 16px;"></td>
                                    <td style="font-size: 16px;"></td>
                                    <td style="font-size: 16px;"></td>
                                    <td style="font-size: 16px;"></td>
                                    <td style="font-size: 16px;"></td>
                                    <td style="font-size: 16px;"></td>
                                    <td style="font-size: 16px;"></td>
                                </tr>
                                <tr>
                                    <th style="font-size: 16px;color: yellow;">Transport</th>
                                    <td style="font-size: 16px;"></td>
                                    <td style="font-size: 16px;"></td>
                                    <td style="font-size: 16px;"></td>
                                    <td style="font-size: 16px;"></td>
                                    <td style="font-size: 16px;"></td>
                                    <td style="font-size: 16px;"></td>
                                    <td style="font-size: 16px;"></td>
                                </tr>
                                <tr>
                                    <th style="font-size: 16px;color: yellow;">Ware House</th>
                                    <td style="font-size: 16px;"></td>
                                    <td style="font-size: 16px;"></td>
                                    <td style="font-size: 16px;"></td>
                                    <td style="font-size: 16px;"></td>
                                    <td style="font-size: 16px;"></td>
                                    <td style="font-size: 16px;"></td>
                                    <td style="font-size: 16px;"></td>
                                </tr> -->
                            </table>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
    <div *ngIf="SelectedTheme=='Emissions Reduction'" class="col-md-5">
        <div
            style="   background-image: linear-gradient(rgb(30, 30, 30), rgb(23, 23, 23)); border: 1px solid #403C3C; min-height:548px;max-height: max-content;     box-shadow: 0 0 20px #000">
            <div class="asImgBoxTop pt-2">
                <div class="asImgBoxTopTxt">Daywise - Emission Reduction </div>
            </div>

            <div class="row">
                <div class="col-md-12" style="display:grid;">
                    <div class="red1">
                        <div class="row" style="padding-left: 1%;
                            padding-right: 1%;">
                            <div class="col-md-12">

                                <div
                                    style="display:flex ;flex-direction: row;justify-content: space-between;padding: 1%;">
                                    <p class="mainGridtitle4">Day Feeds </p>

                                </div>
                                <!-- <hr> -->

                                <div class="row" style="padding: 1%;">
                                    <div class="col-md-4">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp">Kiln Feed (Ton)</label>
                                            <div *ngFor="let vvald of Valuesdaywise">
                                                <label class="per">{{vvald.kiln_feed_ton}}</label>
                                                <label class="per"></label>
                                            </div>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp">Main Coal(Ton)</label>
                                            <div *ngFor="let vvald of Valuesdaywise">
                                                <label class="per">{{vvald.main_coal_feed_ton}}</label>
                                                <label class="per"></label>
                                            </div>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp">PC Coal(Ton)</label>
                                            <div *ngFor="let vvald of Valuesdaywise">
                                                <label class="per">{{vvald.pc_coal_feed_ton}}</label>
                                                <label class="per"></label>
                                            </div>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" style="display:grid;">
                    <div class="red1">
                        <div class="row" style="padding-left: 1%;
                            padding-right: 1%;">
                            <div class="col-md-12">

                                <div
                                    style="display:flex ;flex-direction: row;justify-content: space-between;padding: 1%;">
                                    <p class="mainGridtitle4">Emission Summary </p>

                                </div>
                                <!-- <hr> -->
                                <!-- headings for the table  -->
                                <div class="row" style="padding: 1%;">
                                    <div class="col-md-2">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp">Flue gas</label>

                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp">Before GreenOps</label>

                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp">After GreenOps</label>

                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp">Savings</label>
                                            <!-- <div>
                                                    <label class="per">328.71</label>
                                                    <label class="per"></label>
                                                </div> -->
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp">Units</label>
                                            <!-- <div>
                                                    <label class="per">328.71</label>
                                                    <label class="per"></label>
                                                </div> -->
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>

                                </div>

                                <!-- co2 -->
                                <div class="row" style="padding: 1%;">
                                    <div class="col-md-2">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp">CO<sub>2</sub></label>
                                            <!-- <div>
                                                    <label class="per"></label>
                                                    <label class="per"></label>
                                                </div> -->
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp" style="color: #fff;">{{vvald.before_CO2}}</label>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <!-- <label for="temp">PC Coal(Ton)</label> -->
                                            <label for="temp" style="color: #fff;">{{vvald.after_CO2}}</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <!-- <label for="temp">PC Coal(Ton)</label> -->
                                            <label for="temp" style="color: #fff;">{{vvald.saving_CO2}}</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <!-- <label for="temp">PC Coal(Ton)</label> -->
                                            <label for="temp" style="color: #fff;">Tons</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                </div>

                                <!-- so2 -->
                                <div class="row" style="padding: 1%;">
                                    <div class="col-md-2">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp">SO<sub>2</sub></label>
                                            <!-- <div>
                                                    <label class="per">8791.86</label>
                                                    <label class="per"></label>
                                                </div> -->
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <!-- <label for="temp">PC Coal(Ton)</label> -->
                                            <label for="temp" style="color: #fff;">{{vvald.before_SO2}}</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <!-- <label for="temp">PC Coal(Ton)</label> -->
                                            <label for="temp" style="color: #fff;">{{vvald.after_SO2}}</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <!-- <label for="temp">PC Coal(Ton)</label> -->
                                            <label for="temp" style="color: #fff;">{{vvald.saving_SO2}}</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp" style="color: #fff;">Kg</label>

                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                </div>

                                <!-- nox -->
                                <div class="row" style="padding: 1%;">
                                    <div class="col-md-2">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp">NO<sub>x</sub> </label>
                                            <!-- <div>
                                                    <label class="per">8791.86</label>
                                                    <label class="per"></label>
                                                </div> -->
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <!-- <label for="temp">PC Coal(Ton)</label> -->
                                            <label for="temp" style="color: #fff;">{{vvald.before_NOX}}</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <!-- <label for="temp">PC Coal(Ton)</label> -->
                                            <label for="temp" style="color: #fff;">{{vvald.after_NOX}}</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <!-- <label for="temp">PC Coal(Ton)</label> -->
                                            <label for="temp" style="color: #fff;">{{vvald.saving_NOX}}</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp" style="color: #fff;">Kg</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                </div>

                                <!-- co -->
                                <div class="row" style="padding: 1%;">
                                    <div class="col-md-2">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp">CO</label>
                                            <!-- <div>
                                                    <label class="per">8791.86</label>
                                                    <label class="per"></label>
                                                </div> -->
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <!-- <label for="temp">PC Coal(Ton)</label> -->
                                            <label for="temp" style="color: #fff;">{{vvald.before_CO}}</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <!-- <label for="temp">PC Coal(Ton)</label> -->
                                            <label for="temp" style="color: #fff;">{{vvald.after_CO}}</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div *ngFor="let vvald of Valuesdaywise" class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <!-- <label for="temp">PC Coal(Ton)</label> -->
                                            <label for="temp" style="color: #fff;">{{vvald.saving_CO}}</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="red" style="border-radius: 6px;
                              box-shadow: inset 7px 7px 14px black,
                                          inset -7px -7px 14px #5a5a5a;">
                                            <label for="temp" style="color: #fff;">Kg</label>
                                            <!-- <div ><label class="per">No data</label></div> -->
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div style="width: 100%;padding-top: 3%;">
                <img class="img-fluid" src="assets\img\cement_img.svg" alt="AluminumSmeltingGraphic"
                    style="width:100%; margin:auto; height: 542px;">
            </div> -->
        </div>
    </div>
    <div *ngIf="SelectedTheme=='Reports Extraction'" class="col-md-10"
        style="background-image: linear-gradient(rgb(30, 30, 30), rgb(23, 23, 23));border: 1px solid #403C3C; min-height: 613px;max-height:max-content; padding-top: 1rem;padding-left: 1rem;    box-shadow: 0 0 20px #000">
        <div class="asImgBoxTop pt-2">
            <div class="asImgBoxTopTxt">Reports Extraction</div>

        </div>
        <div class="col-md-12" style="display:grid;">
            <div class="row" style="padding-top: 15px;">
                <div (click)="downloadExcel();" class="col-md-3" style="display:grid;">
                    <img src="assets\images\indian-flag.PNG" alt="Pyro Process flow"
                        style="width:100%;height: 100px;cursor:pointer">
                    <span style="color: #fff;">BS & SR</span>
                </div>
                <div class="col-md-3" style="display:grid;">
                    <img src="assets\images\us-flag.png" alt="Pyro Process flow"
                        style="width:100%;height: 100px;filter: blur(2px);cursor:pointer">
                    <span style="color: #fff;">Mandatory Reporting of GHG Rule</span>
                </div>
                <div class="col-md-3" style="display:grid;">
                    <img src="assets\images\uk-flag.png" alt="Pyro Process flow"
                        style="width:100%;height: 100px;filter: blur(2px);cursor:pointer">
                    <span style="color: #fff;">Guidance on how to measure your GHG Emmisions</span>

                </div>
                <div class="col-md-3" style="display:grid;">
                    <img src="assets\images\japan-flag.png" alt="Pyro Process flow"
                        style="width:100%;height: 100px;filter: blur(2px);cursor:pointer">
                    <span style="color: #fff;">Tokyo Emission Trading Scheme, Mandatory GHG Accounting and Reporting
                        System </span>
                </div>

                <!-- <div class="col-md-3">
                    <div class="red"  style="border-radius: 6px;
box-shadow: inset 7px 7px 14px black,
        inset -7px -7px 14px #5a5a5a;">
                    </div>
                </div> -->

            </div>
            <div class="row" style="padding-top: 15px;">
                <div class="col-md-3" style="display:grid;">
                    <img src="assets\images\australia-flag.png" alt="Pyro Process flow"
                        style="width:100%;height: 100px; filter: blur(2px);cursor:pointer">
                    <span style="color: #fff;">NGER Mandatory Reporting</span>

                </div>
                <div class="col-md-3" style="display:grid;">
                    <img src="assets\images\nz-flag.png" alt="Pyro Process flow"
                        style="width:100%;height: 100px; filter: blur(2px);cursor:pointer">
                    <span style="color: #fff;">Guidance for Voluntary Corporate GHG Reporting </span>

                </div>
                <div class="col-md-3" style="display:grid;">
                    <img src="assets\images\canada-flag.png" alt="Pyro Process flow"
                        style="width:100%;height: 100px; filter: blur(2px);cursor:pointer">
                    <span style="color: #fff;">GHG Emission Reporting Scheme</span>

                </div>

            </div>

            <!-- <img class="img-fluid" src="assets\images\pillars.png" alt="Pyro Process flow"
                    style="width:100%; margin:auto;"> -->
        </div>


    </div>
    <div *ngIf="SelectedTheme=='GreenOps - Data Config.'" class="col-md-10" style="background-image: linear-gradient(rgb(30, 30, 30), rgb(23, 23, 23));border: 1px solid #403C3C; 
        min-height: 613px;max-height:fit-content; padding-top: 1rem;padding-left: 1rem;    
        box-shadow: 0 0 20px #000">
         <div class="col-md-10" style="display:flex;flex-direction: row;justify-content: space-between;margin-left: 8%;margin-right: 8%;">
            <div class="col-md-3" style="height:40px ;">
                <div class="red" style="border-radius: 6px;
box-shadow: inset 7px 7px 14px black,
inset -7px -7px 14px #5a5a5a;height:24px ;">
                    <label style="color: #fff;">Data Config</label>
                </div>
            </div>
            <div class="col-md-3" style="height:40px ;">
                <div class="red" style="border-radius: 6px;
box-shadow: inset 7px 7px 14px black,
inset -7px -7px 14px #5a5a5a;height:24px ;">
                    <label style="color: #fff;">Data Processing</label>
                </div>
            </div>
            <div class="col-md-3" style="height:40px ;">
                <div class="red" style="border-radius: 6px;
box-shadow: inset 7px 7px 14px black,
inset -7px -7px 14px #5a5a5a;height:24px ;">
                    <label style="color: #fff;">Report</label>
                </div>
            </div>
        </div>
        <div class="col-md-12" style="display:grid;">
            <img class="img-fluid" src="assets\images\greenopsi.svg" alt="Pyro Process flow"
                style="width:100%;height: 530px;">
        </div>


    </div>
    <div *ngIf="SelectedTheme=='Validation - Workflow'" class="col-md-10" style="background-image: linear-gradient(rgb(30, 30, 30), rgb(23, 23, 23));border: 1px solid #403C3C; 
    min-height: 613px;max-height:fit-content; padding-top: 1rem;padding-left: 1rem;    
    box-shadow: 0 0 20px #000">
        <div class="col-md-12" style="display:grid;">
            <section>
                <div class="mainGridRgtTitl"
                    style="display:flex;flex-direction:row;justify-content:space-between;padding-bottom: 1%;margin-bottom: 1%;">
                    Validation - Workflow

                </div>
                <div style="
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
            ">
                    <p style="
                margin: 0px;
                margin-left: 10px;
                font-weight: 400;
                font-size: 20px;
                color: #fff;
              ">
                        Status:
                    </p>
                    &nbsp;&nbsp;
                    <button style="
                height: 10px;
                width: 17px;
                left: 66px;
                top: 860px;
                margin-top: 11px;
                border-radius: 10px;
                border-width: 0px;
                /* background: #0eab73; */
              " [ngStyle]="{
                background:
                  this.colorMix1 == 'G' ? '#39FF14' : '#0a3207'
              }"></button>&nbsp;&nbsp;
                    <button style="
                height: 10px;
                width: 17px;
                left: 66px;
                top: 860px;
                margin-top: 11px;
                border-radius: 10px;
                border-width: 0px;
                /* background: #e5b027; */
              " [ngStyle]="{
                background:
                  this.colorMix1 == 'Y' ? '#e5b027' : '#332f00'
              }"></button>&nbsp;&nbsp;
                    <button style="
                height: 10px;
                width: 17px;
                left: 66px;
                top: 860px;
                margin-top: 11px;
                border-radius: 10px;
                border-width: 0px;
                /* background: #c7152a; */
              " [ngStyle]="{
                background: this.colorMix1 == 'R' ? '#c7152a' : '#300'
              }"></button>&nbsp;&nbsp;

                </div>
                <div class="row">
                    <div class="col-md-2" style="height:200px ;">
                        <div class="red" style="border-radius: 6px;
box-shadow: inset 7px 7px 14px black,
inset -7px -7px 14px #5a5a5a;height:200px ;">
                            <label>Data Capture</label>
                            <label><img  src="assets\images\orange-arr.gif" alt="Pyro Process flow"
                                style="width:40px;height: 40px;"></label>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="red" style="border-radius: 6px;
box-shadow: inset 7px 7px 14px black,
inset -7px -7px 14px #5a5a5a;height:200px ;">
                            <label>Review</label>
                            <label><img  src="assets\images\orange-arr.gif" alt="Pyro Process flow"
                                style="width:40px;height: 40px;"></label>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="red" style="border-radius: 6px;
box-shadow: inset 7px 7px 14px black,
inset -7px -7px 14px #5a5a5a;height:200px ;">
                            <label>Computation with Soft Sensors</label>
                            <label><img  src="assets\images\orange-arr.gif" alt="Pyro Process flow"
                                style="width:40px;height: 40px;"></label>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="red" style="border-radius: 6px;
box-shadow: inset 7px 7px 14px black,
inset -7px -7px 14px #5a5a5a;height:200px ;">
                            <label>Review</label>
                            <label><img  src="assets\images\orange-arr.gif" alt="Pyro Process flow"
                                style="width:40px;height: 40px;"></label>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="red" style="border-radius: 6px;
box-shadow: inset 7px 7px 14px black,
inset -7px -7px 14px #5a5a5a;height:200px ;">
                            <label>Approval</label>
                            <label><img  src="assets\images\orange-arr.gif" alt="Pyro Process flow"
                                style="width:40px;height: 40px;"></label>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="red" style="border-radius: 6px;
box-shadow: inset 7px 7px 14px black,
inset -7px -7px 14px #5a5a5a;height:200px ;">
                            <label>Ready</label>
                            <label><img  src="assets\images\tick-n.gif" alt="Pyro Process flow"
                                style="width:60px;height: 60px;"></label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- <div class="col-md-12">
                        <label style="display: flex;flex-direction:row;justify-content:center;color:#fff">Report Validation</label>
                        
                     
                    </div> -->
                </div>

                <!-- <div style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  
                  /* padding: 1%; */
                  margin-top: 2%;
                ">
              <button mat-raised-button color="primary" style="width: 150px;margin-left: 15px; " (click)="Validationcheck('G')">
                Submission to <br/>Customer Review
              </button>
              <button mat-raised-button color="primary" style="width: 150px; " (click)="Validationcheck('G')">
                Customer <br/>Review
              </button>
              <button mat-raised-button color="primary" style="width: 150px; " (click)="Validationcheck('G')">
                Computing<br/> Outcome
              </button>
              <button mat-raised-button color="primary" style="width: 150px; " (click)="Validationcheck('G')">
                Report <br/>Preparation
              </button>
              <button mat-raised-button color="primary" style="width: 150px; " (click)="Validationcheck('G')">
                Customer <br/>Review
              </button>
              <button mat-raised-button color="primary" style="width: 150px; "  (click)="Validationcheck('G')">
                Final <br/>Submitted
              </button>
            </div> -->

            </section>
        </div>


    </div>

    <!-- <div *ngIf="SelectedTheme=='ESG'">
                <app-theme5></app-theme5>
            </div> -->

    <div *ngIf="SelectedTheme!='Reports Extraction'&& SelectedTheme!='GreenOps - Data Config.' && SelectedTheme!='Validation - Workflow'"
        class="col-md-5" style="background-image: linear-gradient(rgb(30, 30, 30), rgb(23, 23, 23));
        border: 1px solid #403C3C; 
        min-height: 613px;max-height:max-content; padding-top: 1rem;    
        box-shadow: 0 0 20px #000">

        <!-- Theme 2 -->
        <!-- <div *ngIf="SelectedTheme=='AFR Vs Coal Trends'">
            <app-theme2 [graph4]="PCCoaldata" [graph5]="AFRCoaldata"></app-theme2>
        </div> -->

        <!-- Theme 1 -->
        <!-- <div *ngIf="SelectedTheme=='KPI Validation'">
                <app-theme1></app-theme1>Validation - Workflow
            </div> -->

        <!-- Theme 1 -->
        <!-- <div *ngIf="SelectedTheme=='Validation - Workflow'">
            <app-theme1></app-theme1>
        </div> -->

        <div *ngIf="SelectedTheme=='Energy, Water & Wastage'">
            <app-theme6> </app-theme6>
            <!-- <app-theme1></app-theme1> -->
        </div>

        <!-- Theme 4  -->
        <div *ngIf="SelectedTheme=='AFR Recommendation'">
            <app-theme4 [graph3]="graphValueafr2" [tableVal]="AFRdata" [AfrInc]="AFRdata1" [CoalDe]="Coaldata"
                [epochtime]="startdatef"> </app-theme4>
        </div>

        <div *ngIf="SelectedTheme=='Emissions Accounting'">
            <app-theme7> </app-theme7>
            <!-- <app-theme1></app-theme1> -->
        </div>

        <!-- Theme 3  -->
        <div *ngIf="SelectedTheme=='Emissions Reduction'">
            <app-theme3 [graph1]="graphValueafr" [graph2]="graphValueafr1" [savings]="Valuesdaywise"></app-theme3>
        </div>

        <!-- <div *ngIf="SelectedTheme=='BS & SR'">
                <app-theme1></app-theme1>
            </div> -->
        <!-- Theme 5 -->

    </div>

</div>

<app-footer></app-footer>