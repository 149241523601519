<section>
  <div class="mainGridRgtTitl"
    style="display:flex;flex-direction:row;justify-content:space-between;padding-bottom: 1%;margin-bottom: 1%;">
    Validation - Workflow

  </div>
  <div style="
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1%;
        margin-top: 2%;
      ">

    <button mat-raised-button color="primary" style="width: 95px; height: 65px;">
      Start
    </button>
    <span style="
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          /* padding: 1%; */
          padding-bottom: 2%;
          margin-top: 2%;
        ">
      <button mat-raised-button color="primary" style="width: 95px; height: 65px;">Next</button>
      <button mat-raised-button color="primary" style="margin-left: 50px; width: 95px; height: 65px;">
        Data Capture
      </button>
    </span>
    <button mat-raised-button color="primary" style="width: 95px; height: 65px;">
      Data Sourcing Config.
    </button>
    <button mat-raised-button color="primary" style="width: 95px; height: 65px; margin-top: 2%">
      Data Cleaning & Validation LNS
    </button>
  </div>
  <div style="
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
        /* padding: 1%; */
        margin-top: 2%;
      ">
    <button mat-raised-button color="primary" style="width: 95px; height: 65px;">
      Submission to Customer Review
    </button>
    <button mat-raised-button color="primary" style="width: 95px; height: 65px;">
      Customer Review
    </button>
    <button mat-raised-button color="primary" style="width: 95px; height: 65px;">
      Computing Outcome
    </button>
    <button mat-raised-button color="primary" style="width: 95px; height: 65px;">
      Report Preparation
    </button>
    <button mat-raised-button color="primary" style="width: 95px; height: 65px;">
      Customer Review
    </button>
    <button mat-raised-button color="primary" style="width: 95px; height: 65px;">
      Final Submitted
    </button>
  </div>

</section>