import { PlotChartsService } from './../../../core/charts/plot-charts.service';
// import { BalcoService } from './../../../core/balco.service';
import { APiService } from 'src/app/core/balco.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';



am4core.useTheme(am4themes_animated);
@Component({
    selector: 'app-theme6',
    templateUrl: './theme6.component.html',
    styleUrls: ['./theme6.component.scss']
  })

export class Theme6Component implements OnInit {
  @Input() graph1: any[];
  @Input() graph2: any[];
  @Input() savings: any[];

  today: number = Date.now();
  kips: any;
  Apilabel: any = 'Actual vs Predicted PO4';
  label: any;
  values: any;
  listOfGraphData = [];
  alumFormVal: any;
  alumTapVal: any;
  anodeVal: any;
  temperatureVal: any;
  errMesage;
  submitted: boolean;
  potNo: any;
  selectProductType = [
    'Actual vs Predicted PO4',
    'Actual vs Predicted pH',
  ];
  selectedValue;
  xalfs: any;
  temps: any;
  boilerNo: any;
  actualVal: any;
  receivedData = [];
  receivedData1 = [];
  selectedGraphAFR = '';
  tableSettings: any = [];
  graphTableValues: any = [];
  graphValue: any;
  appliedDateFilters: any = {};
  maxDate: Date = new Date();
  AFRdata: any = [];
  AFRdata1: any = [];
  Coaldata: any = [];
  startRealTime = '1680373800';

  graphValueafr: any = [];
  graphValueafr1: any = [];
  selectedDatenext: any;
  ewwdata: any;
  s3Inten: any;
  s4Inten: any;

  constructor(private ApicallService: APiService,
    private plotChartsService: PlotChartsService,
    public dialog: MatDialog) {
    this.ApicallService.boilerNo.subscribe((res) => {
      this.boilerNo = res;
    });


  }

  ngOnInit() {
    this.selectedDatenext = '1680373800'
    this.getEWWdata();
    this.getScope3();
    this.getScope4();
    // this.getAFRTable();
    // this.getAFRTable1();
    // this.getCOTable1();
    // this.getCoGraph();
    // this.getCoGraph1();
    console.log(this.graph1, this.graph2, this.savings)
  }

  ngOnChanges() {
    // this.getAFRTable();
    // this.getAFRTable1();
    // this.getCOTable1();
    // this.getCoGraph();
    // this.getCoGraph1();
    
    // this.drawCOChart();
    // this.drawCOChart1();
  }
  // changeDropdown(types: any) {
  //   this.selectedDatenext = types.target.value
  //   // console.log(this.selectedDatenext, "jhv")
  //   this.getCOTable1()
  //   this.getCoGraph();
  //   this.getCoGraph1();
  //   this.drawCOChart();
  //   this.drawCOChart1();

  // }
  getScope3() {
    this.ApicallService.getIntensityS3().subscribe(
      (response: any) => {
        this.s3Inten = response;
        // console.log(this.htmlContent, "data")
      },
      (err) => {
        // (err);

      }
    )
  }

  getScope4() {
    this.ApicallService.getIntensityS4().subscribe(
      (response: any) => {
        this.s4Inten = response;
        // console.log(this.htmlContent, "data")
      },
      (err) => {
        // (err);

      }
    )
  }

  getEWWdata(){
    this.ApicallService.getEWW().subscribe(
      (data: any) => {
        this.ewwdata = data;
        // // console.log(this.AFRdata, "data")
      },
      (err) => {
        // (err);

      }
    )
  }
  getAFRTable() {
    this.ApicallService.getAFRTableValues(this.startRealTime).subscribe(
      (data: any) => {
        this.AFRdata = data;
        // // console.log(this.AFRdata, "data")
      },
      (err) => {
        // (err);

      }
    )

  }


  getAFRTable1() {
    this.ApicallService.getAFRTableValues1(this.selectedDatenext).subscribe(
      (data: any) => {
        this.AFRdata1 = data;
        // // console.log(this.AFRdata, "data")
      },
      (err) => {
        // (err);

      }
    )

  }
  getCOTable1() {
    this.ApicallService.getCOtableValues1(this.selectedDatenext).subscribe(
      (data: any) => {
        this.Coaldata = data;
        // // console.log(this.AFRdata, "data")
      },
      (err) => {
        // (err);

      }
    )

  }

  getCoGraph() {
    let ref = this;
    ref.plotChartsService.getCOdata1(this.selectedDatenext).subscribe((res) => {
      ref.graphValueafr = res;
      ref.graphValueafr.map((cvalue: any) => {
        let dt = cvalue.obs_time;
        let forcast = cvalue.coal_before;
        let temp = cvalue.coal_after;
      })
      // console.log("jhbh", ref.graphValueafr)
      ref.drawCOChart();
      ref.drawCOChart1();
      // ref.drawFC2Chart();

    });
  }
  getCoGraph1() {
    let ref = this;
    ref.plotChartsService.getCOdata2(this.selectedDatenext).subscribe((res) => {
      ref.graphValueafr1 = res;
      ref.graphValueafr1.map((cvalue: any) => {
        let dt = cvalue.obs_time;
        let forcast = cvalue.co_before;
        let temp = cvalue.co_after;
      })
      // console.log("jhbh", ref.graphValueafr)
      ref.drawCOChart();
      ref.drawCOChart1();
      // ref.drawFC2Chart();

    });
  }



  drawCOChart() {
    setTimeout(() => {
      let ref = this;
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      let chart = am4core.create("ppediv", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 25;
      chart.background.fill = am4core.color('#1e1e1e');
      // chart.tooltip.label.fill = am4core.color("#fff");

      // Add data
      chart.data = ref.graph1;



      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time";
      categoryAxis.title.text = "Time";
      categoryAxis.title.fill = am4core.color("#FFF");
      // categoryAxis.title.fontSize = 10;
      // categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.labels.template.fill = am4core.color('#fff');
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;

      // categoryAxis.tooltipDateFormat = 'd MMM, hh-mm a';
      // categoryAxis.renderer.grid.template.stroke = "#fff";
      // categoryAxis.renderer.cellStartLocation = 0.1;
      // categoryAxis.renderer.cellEndLocation = 0.9;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.title.text = "Tons";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.title.fontWeight = 'bold';
      valueAxis.title.fontSize = 12;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color('#fff');

      // Create series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.width = am4core.percent(30);
      series.dataFields.valueY = "CO2_ton";
      series.dataFields.categoryX = "obs_time";
      series.tooltipText = "CO2 Before GreenOps" + `:{${"CO2_ton"}}`;
      // series.strokeWidth = 2;
      series.stroke = am4core.color("#FFD1A2");
      series.fill = am4core.color("#FFD1A2");
      // series.tooltip.label.propertyFields.fill = "#fff";
      series.legendSettings.labelText = "CO2 Before GreenOps [bold][/]";
      // series.label.text ="{valueY}";

      let series1 = chart.series.push(new am4charts.LineSeries());
      series1.dataFields.valueY = "CO2_reduced_ton";
      series1.dataFields.categoryX = "obs_time";
      series1.tooltipText = "CO2 Saved" + `:{${"CO2_reduced_ton"}}`;
      series1.strokeWidth = 2;
      series1.stroke = am4core.color("#00FFC7");
      series1.fill = am4core.color("#00FFC7");
      series1.legendSettings.labelText = "CO2 Saved [bold][/]";

      let series2 = chart.series.push(new am4charts.LineSeries());
      series2.dataFields.valueY = "CO2_after_greenops";
      series2.dataFields.categoryX = "obs_time";
      series2.tooltipText = "CO2 After GreenOps" + `:{${"CO2_after_greenops"}}`;
      series2.strokeWidth = 2;
      series2.stroke = am4core.color("#b3ff66");
      series2.fill = am4core.color("#b3ff66");
      series2.legendSettings.labelText = "CO2 After GreenOps [bold][/]";

      let bullet2 = series1.bullets.push(new am4charts.Bullet());
      bullet2.fill = am4core.color("#4464a8");
      let circleBullet = series1.bullets.push(new am4charts.CircleBullet());
      circleBullet.circle.radius = 2;

      let bullet = series2.bullets.push(new am4charts.Bullet());
      bullet.fill = am4core.color("#3BFF00");
      let circleBullet1 = series2.bullets.push(new am4charts.CircleBullet());
      circleBullet1.circle.radius = 2;

      // Add legend
      chart.legend = new am4charts.Legend();
      chart.legend.position = "top";
      chart.legend.labels.template.fill = am4core.color("#fff");

      //Add scrollbar
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.topAxesContainer
      chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.minHeight = 7;
      // Applied on hover
      chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      // Applied on mouse down
      chart.scrollbarX.startGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");


      chart.tooltip.label.maxWidth = 150;
      chart.tooltip.label.wrap = true;


      // ref.loaded = true;
      // ref.isLoading=false;

    }, 100);
  }
  drawCOChart1() {
    setTimeout(() => {
      let ref = this;
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      let chart = am4core.create("ppediv1", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 25;
      chart.background.fill = am4core.color('#1e1e1e');
      // chart.tooltip.label.fill = am4core.color("#fff");

      // Add data
      chart.data = ref.graph2;



      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time";
      categoryAxis.title.text = "Time";
      categoryAxis.title.fill = am4core.color("#FFF");
      // categoryAxis.title.fontSize = 10;
      // categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.labels.template.fill = am4core.color('#fff');
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;

      // categoryAxis.tooltipDateFormat = 'd MMM, hh-mm a';
      // categoryAxis.renderer.grid.template.stroke = "#fff";
      // categoryAxis.renderer.cellStartLocation = 0.1;
      // categoryAxis.renderer.cellEndLocation = 0.9;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.title.text = "Kg";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.title.fontWeight = 'bold';
      valueAxis.title.fontSize = 12;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color('#fff');

      // Create series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.width = am4core.percent(30);
      series.dataFields.valueY = "CO_kg";
      series.dataFields.categoryX = "obs_time";
      series.tooltipText = "CO before GreenOps" + `:{${"CO_kg"}}`;
      // series.strokeWidth = 5;
      series.stroke = am4core.color("#9AAFFF");
      series.fill = am4core.color("#9AAFFF");
      // series.tooltip.label.propertyFields.fill = "#fff";
      series.legendSettings.labelText = "CO before GreenOps [bold][/]";

      // series.label.text ="{valueY}";

      let series1 = chart.series.push(new am4charts.LineSeries());
      series1.dataFields.valueY = "CO_reduced_kg";
      series1.dataFields.categoryX = "obs_time";
      series1.tooltipText = "CO Saved" + `:{${"CO_reduced_kg"}}`;
      series1.strokeWidth = 2;
      series1.stroke = am4core.color("#00FFC7");
      series1.fill = am4core.color("#00FFC7");
      series1.legendSettings.labelText = "CO Saved [bold {}][/]";

      let series2 = chart.series.push(new am4charts.LineSeries());
      series2.dataFields.valueY = "CO_after_greenops";
      series2.dataFields.categoryX = "obs_time";
      series2.tooltipText = "CO After GreenOps" + `:{${"CO_after_greenops"}}`;
      series2.strokeWidth = 2;
      series2.stroke = am4core.color("#b3ff66");
      series2.fill = am4core.color("#b3ff66");
      series2.legendSettings.labelText = "CO After GreenOps [bold][/]";

      // Add legend
      chart.legend = new am4charts.Legend();
      chart.legend.position = "top";
      chart.legend.labels.template.fill = am4core.color("#fff");

      let bullet2 = series1.bullets.push(new am4charts.Bullet());
      bullet2.fill = am4core.color("#4464a8");
      let circleBullet = series1.bullets.push(new am4charts.CircleBullet());
      circleBullet.circle.radius = 2;

      let bullet = series2.bullets.push(new am4charts.Bullet());
      bullet.fill = am4core.color("#3BFF00");
      let circleBullet1 = series2.bullets.push(new am4charts.CircleBullet());
      circleBullet1.circle.radius = 2;


      //Add scrollbar
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.topAxesContainer
      chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.minHeight = 7;
      // Applied on hover
      chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      // Applied on mouse down
      chart.scrollbarX.startGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");


      chart.tooltip.label.maxWidth = 150;
      chart.tooltip.label.wrap = true;


      // ref.loaded = true;
      // ref.isLoading=false;

    }, 100);
  }

  onNextclick() {
    this.selectedDatenext = parseInt(this.selectedDatenext) + 300;
    console.log(this.selectedDatenext)
    this.ApicallService.getAFRTableValues(this.selectedDatenext).subscribe(
      (data: any) => {
        this.AFRdata = data;
        // // console.log(this.AFRdata, "data")
      },
      (err) => {
        // (err);
      }
    )

  }
  onPreviousclick() {
    this.selectedDatenext = parseInt(this.selectedDatenext) - 300;
    // // console.log(this.onclicknext)
    this.ApicallService.getAFRTableValues(this.selectedDatenext).subscribe(
      (data: any) => {
        this.AFRdata = data;
        // // console.log(this.AFRdata, "data")
      },
      (err) => {
        // (err);
      }
    )

  }

}




